
.LoginAnimationBg{
    position: fixed;
    top:0;
    left:0;
    right:0;
    bottom:0;
    width:100vw;
    height:100vh;
    z-index: 0;
}


  .container {
    width: 100%;
    height: 100%;
    overflow: hidden;
    /* position: relative; */
    z-index:200;
    margin-right: 100%;
  }
  
  /* .background {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    -o-object-fit: cover;
       object-fit: cover;
    width: 100%;
    height: 100%;
    -webkit-mask-image: radial-gradient(white 0%, white 30%, transparent 80%, transparent);
            mask-image: radial-gradient(white 0%, white 30%, transparent 80%, transparent);
  } */
  
  .circle-container {
    position: absolute;
    transform: translateY(-50vh);
    -webkit-animation-iteration-count: infinite;
            animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
            animation-timing-function: linear;
    z-index: 20,
  }
  .circle-container .circle {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    mix-blend-mode: screen;
    background-image: radial-gradient(#ffffff, #ffffff 10%, rgba(255, 255, 255, 0) 56%);
    -webkit-animation: fadein-frames 200ms infinite, scale-frames 2s infinite;
            animation: fadein-frames 200ms infinite, scale-frames 2s infinite;
  }
  @-webkit-keyframes fade-frames {
    0% {
      opacity: 1;
    }
    50% {
      opacity: 0.7;
    }
    100% {
      opacity: 1;
    }
  }
  @keyframes fade-frames {
    0% {
      opacity: 1;
    }
    50% {
      opacity: 0.7;
    }
    100% {
      opacity: 1;
    }
  }
  @-webkit-keyframes scale-frames {
    0% {
      transform: scale3d(0.4, 0.4, 1);
    }
    50% {
      transform: scale3d(2.2, 2.2, 1);
    }
    100% {
      transform: scale3d(0.4, 0.4, 1);
    }
  }
  @keyframes scale-frames {
    0% {
      transform: scale3d(0.4, 0.4, 1);
    }
    50% {
      transform: scale3d(2.2, 2.2, 1);
    }
    100% {
      transform: scale3d(0.4, 0.4, 1);
    }
  }
  .circle-container:nth-child(1) {
    width: 7px;
    height: 7px;
    -webkit-animation-name: move-frames-1;
            animation-name: move-frames-1;
    -webkit-animation-duration: 36017ms;
            animation-duration: 36017ms;
    -webkit-animation-delay: 13370ms;
            animation-delay: 13370ms;
  }
  @-webkit-keyframes move-frames-1 {
    from {
      transform: translate3d(93vw, 103vh, 0);
    }
    to {
      transform: translate3d(100vw, -110vh, 0);
    }
  }
  @keyframes move-frames-1 {
    from {
      transform: translate3d(93vw, 103vh, 0);
    }
    to {
      transform: translate3d(100vw, -110vh, 0);
    }
  }
  .circle-container:nth-child(1) .circle {
    -webkit-animation-delay: 420ms;
            animation-delay: 420ms;
  }
  .circle-container:nth-child(2) {
    width: 8px;
    height: 8px;
    -webkit-animation-name: move-frames-2;
            animation-name: move-frames-2;
    -webkit-animation-duration: 29036ms;
            animation-duration: 29036ms;
    -webkit-animation-delay: 30910ms;
            animation-delay: 30910ms;
  }
  @-webkit-keyframes move-frames-2 {
    from {
      transform: translate3d(53vw, 102vh, 0);
    }
    to {
      transform: translate3d(33vw, -125vh, 0);
    }
  }
  @keyframes move-frames-2 {
    from {
      transform: translate3d(53vw, 102vh, 0);
    }
    to {
      transform: translate3d(33vw, -125vh, 0);
    }
  }
  .circle-container:nth-child(2) .circle {
    -webkit-animation-delay: 1476ms;
            animation-delay: 1476ms;
  }
  .circle-container:nth-child(3) {
    width: 7px;
    height: 7px;
    -webkit-animation-name: move-frames-3;
            animation-name: move-frames-3;
    -webkit-animation-duration: 35583ms;
            animation-duration: 35583ms;
    -webkit-animation-delay: 36514ms;
            animation-delay: 36514ms;
  }
  @-webkit-keyframes move-frames-3 {
    from {
      transform: translate3d(88vw, 103vh, 0);
    }
    to {
      transform: translate3d(69vw, -123vh, 0);
    }
  }
  @keyframes move-frames-3 {
    from {
      transform: translate3d(88vw, 103vh, 0);
    }
    to {
      transform: translate3d(69vw, -123vh, 0);
    }
  }
  .circle-container:nth-child(3) .circle {
    -webkit-animation-delay: 3980ms;
            animation-delay: 3980ms;
  }
  .circle-container:nth-child(4) {
    width: 5px;
    height: 5px;
    -webkit-animation-name: move-frames-4;
            animation-name: move-frames-4;
    -webkit-animation-duration: 36585ms;
            animation-duration: 36585ms;
    -webkit-animation-delay: 20229ms;
            animation-delay: 20229ms;
  }
  @-webkit-keyframes move-frames-4 {
    from {
      transform: translate3d(44vw, 101vh, 0);
    }
    to {
      transform: translate3d(48vw, -122vh, 0);
    }
  }
  @keyframes move-frames-4 {
    from {
      transform: translate3d(44vw, 101vh, 0);
    }
    to {
      transform: translate3d(48vw, -122vh, 0);
    }
  }
  .circle-container:nth-child(4) .circle {
    -webkit-animation-delay: 883ms;
            animation-delay: 883ms;
  }
  .circle-container:nth-child(5) {
    width: 8px;
    height: 8px;
    -webkit-animation-name: move-frames-5;
            animation-name: move-frames-5;
    -webkit-animation-duration: 33947ms;
            animation-duration: 33947ms;
    -webkit-animation-delay: 3262ms;
            animation-delay: 3262ms;
  }
  @-webkit-keyframes move-frames-5 {
    from {
      transform: translate3d(1vw, 102vh, 0);
    }
    to {
      transform: translate3d(49vw, -130vh, 0);
    }
  }
  @keyframes move-frames-5 {
    from {
      transform: translate3d(1vw, 102vh, 0);
    }
    to {
      transform: translate3d(49vw, -130vh, 0);
    }
  }
  .circle-container:nth-child(5) .circle {
    -webkit-animation-delay: 1153ms;
            animation-delay: 1153ms;
  }
  .circle-container:nth-child(6) {
    width: 5px;
    height: 5px;
    -webkit-animation-name: move-frames-6;
            animation-name: move-frames-6;
    -webkit-animation-duration: 28695ms;
            animation-duration: 28695ms;
    -webkit-animation-delay: 13951ms;
            animation-delay: 13951ms;
  }
  @-webkit-keyframes move-frames-6 {
    from {
      transform: translate3d(25vw, 104vh, 0);
    }
    to {
      transform: translate3d(19vw, -119vh, 0);
    }
  }
  @keyframes move-frames-6 {
    from {
      transform: translate3d(25vw, 104vh, 0);
    }
    to {
      transform: translate3d(19vw, -119vh, 0);
    }
  }
  .circle-container:nth-child(6) .circle {
    -webkit-animation-delay: 2613ms;
            animation-delay: 2613ms;
  }
  .circle-container:nth-child(7) {
    width: 1px;
    height: 1px;
    -webkit-animation-name: move-frames-7;
            animation-name: move-frames-7;
    -webkit-animation-duration: 31573ms;
            animation-duration: 31573ms;
    -webkit-animation-delay: 26621ms;
            animation-delay: 26621ms;
  }
  @-webkit-keyframes move-frames-7 {
    from {
      transform: translate3d(26vw, 109vh, 0);
    }
    to {
      transform: translate3d(59vw, -127vh, 0);
    }
  }
  @keyframes move-frames-7 {
    from {
      transform: translate3d(26vw, 109vh, 0);
    }
    to {
      transform: translate3d(59vw, -127vh, 0);
    }
  }
  .circle-container:nth-child(7) .circle {
    -webkit-animation-delay: 3608ms;
            animation-delay: 3608ms;
  }
  .circle-container:nth-child(8) {
    width: 3px;
    height: 3px;
    -webkit-animation-name: move-frames-8;
            animation-name: move-frames-8;
    -webkit-animation-duration: 34130ms;
            animation-duration: 34130ms;
    -webkit-animation-delay: 2914ms;
            animation-delay: 2914ms;
  }
  @-webkit-keyframes move-frames-8 {
    from {
      transform: translate3d(50vw, 105vh, 0);
    }
    to {
      transform: translate3d(45vw, -124vh, 0);
    }
  }
  @keyframes move-frames-8 {
    from {
      transform: translate3d(50vw, 105vh, 0);
    }
    to {
      transform: translate3d(45vw, -124vh, 0);
    }
  }
  .circle-container:nth-child(8) .circle {
    -webkit-animation-delay: 788ms;
            animation-delay: 788ms;
  }
  .circle-container:nth-child(9) {
    width: 7px;
    height: 7px;
    -webkit-animation-name: move-frames-9;
            animation-name: move-frames-9;
    -webkit-animation-duration: 36858ms;
            animation-duration: 36858ms;
    -webkit-animation-delay: 34231ms;
            animation-delay: 34231ms;
  }
  @-webkit-keyframes move-frames-9 {
    from {
      transform: translate3d(46vw, 104vh, 0);
    }
    to {
      transform: translate3d(17vw, -121vh, 0);
    }
  }
  @keyframes move-frames-9 {
    from {
      transform: translate3d(46vw, 104vh, 0);
    }
    to {
      transform: translate3d(17vw, -121vh, 0);
    }
  }
  .circle-container:nth-child(9) .circle {
    -webkit-animation-delay: 1217ms;
            animation-delay: 1217ms;
  }
  .circle-container:nth-child(10) {
    width: 7px;
    height: 7px;
    -webkit-animation-name: move-frames-10;
            animation-name: move-frames-10;
    -webkit-animation-duration: 31234ms;
            animation-duration: 31234ms;
    -webkit-animation-delay: 22511ms;
            animation-delay: 22511ms;
  }
  @-webkit-keyframes move-frames-10 {
    from {
      transform: translate3d(1vw, 107vh, 0);
    }
    to {
      transform: translate3d(79vw, -131vh, 0);
    }
  }
  @keyframes move-frames-10 {
    from {
      transform: translate3d(1vw, 107vh, 0);
    }
    to {
      transform: translate3d(79vw, -131vh, 0);
    }
  }
  .circle-container:nth-child(10) .circle {
    -webkit-animation-delay: 362ms;
            animation-delay: 362ms;
  }
  .circle-container:nth-child(11) {
    width: 2px;
    height: 2px;
    -webkit-animation-name: move-frames-11;
            animation-name: move-frames-11;
    -webkit-animation-duration: 36924ms;
            animation-duration: 36924ms;
    -webkit-animation-delay: 24607ms;
            animation-delay: 24607ms;
  }
  @-webkit-keyframes move-frames-11 {
    from {
      transform: translate3d(67vw, 105vh, 0);
    }
    to {
      transform: translate3d(22vw, -132vh, 0);
    }
  }
  @keyframes move-frames-11 {
    from {
      transform: translate3d(67vw, 105vh, 0);
    }
    to {
      transform: translate3d(22vw, -132vh, 0);
    }
  }
  .circle-container:nth-child(11) .circle {
    -webkit-animation-delay: 1065ms;
            animation-delay: 1065ms;
  }
  .circle-container:nth-child(12) {
    width: 2px;
    height: 2px;
    -webkit-animation-name: move-frames-12;
            animation-name: move-frames-12;
    -webkit-animation-duration: 36569ms;
            animation-duration: 36569ms;
    -webkit-animation-delay: 10275ms;
            animation-delay: 10275ms;
  }
  @-webkit-keyframes move-frames-12 {
    from {
      transform: translate3d(24vw, 110vh, 0);
    }
    to {
      transform: translate3d(15vw, -123vh, 0);
    }
  }
  @keyframes move-frames-12 {
    from {
      transform: translate3d(24vw, 110vh, 0);
    }
    to {
      transform: translate3d(15vw, -123vh, 0);
    }
  }
  .circle-container:nth-child(12) .circle {
    -webkit-animation-delay: 2629ms;
            animation-delay: 2629ms;
  }
  .circle-container:nth-child(13) {
    width: 1px;
    height: 1px;
    -webkit-animation-name: move-frames-13;
            animation-name: move-frames-13;
    -webkit-animation-duration: 31709ms;
            animation-duration: 31709ms;
    -webkit-animation-delay: 28517ms;
            animation-delay: 28517ms;
  }
  @-webkit-keyframes move-frames-13 {
    from {
      transform: translate3d(17vw, 106vh, 0);
    }
    to {
      transform: translate3d(14vw, -123vh, 0);
    }
  }
  @keyframes move-frames-13 {
    from {
      transform: translate3d(17vw, 106vh, 0);
    }
    to {
      transform: translate3d(14vw, -123vh, 0);
    }
  }
  .circle-container:nth-child(13) .circle {
    -webkit-animation-delay: 2211ms;
            animation-delay: 2211ms;
  }
  .circle-container:nth-child(14) {
    width: 8px;
    height: 8px;
    -webkit-animation-name: move-frames-14;
            animation-name: move-frames-14;
    -webkit-animation-duration: 36367ms;
            animation-duration: 36367ms;
    -webkit-animation-delay: 934ms;
            animation-delay: 934ms;
  }
  @-webkit-keyframes move-frames-14 {
    from {
      transform: translate3d(40vw, 102vh, 0);
    }
    to {
      transform: translate3d(15vw, -126vh, 0);
    }
  }
  @keyframes move-frames-14 {
    from {
      transform: translate3d(40vw, 102vh, 0);
    }
    to {
      transform: translate3d(15vw, -126vh, 0);
    }
  }
  .circle-container:nth-child(14) .circle {
    -webkit-animation-delay: 865ms;
            animation-delay: 865ms;
  }
  .circle-container:nth-child(15) {
    width: 3px;
    height: 3px;
    -webkit-animation-name: move-frames-15;
            animation-name: move-frames-15;
    -webkit-animation-duration: 35130ms;
            animation-duration: 35130ms;
    -webkit-animation-delay: 7076ms;
            animation-delay: 7076ms;
  }
  @-webkit-keyframes move-frames-15 {
    from {
      transform: translate3d(28vw, 104vh, 0);
    }
    to {
      transform: translate3d(11vw, -116vh, 0);
    }
  }
  @keyframes move-frames-15 {
    from {
      transform: translate3d(28vw, 104vh, 0);
    }
    to {
      transform: translate3d(11vw, -116vh, 0);
    }
  }
  .circle-container:nth-child(15) .circle {
    -webkit-animation-delay: 1795ms;
            animation-delay: 1795ms;
  }
  .circle-container:nth-child(16) {
    width: 8px;
    height: 8px;
    -webkit-animation-name: move-frames-16;
            animation-name: move-frames-16;
    -webkit-animation-duration: 36706ms;
            animation-duration: 36706ms;
    -webkit-animation-delay: 32089ms;
            animation-delay: 32089ms;
  }
  @-webkit-keyframes move-frames-16 {
    from {
      transform: translate3d(97vw, 108vh, 0);
    }
    to {
      transform: translate3d(40vw, -133vh, 0);
    }
  }
  @keyframes move-frames-16 {
    from {
      transform: translate3d(97vw, 108vh, 0);
    }
    to {
      transform: translate3d(40vw, -133vh, 0);
    }
  }
  .circle-container:nth-child(16) .circle {
    -webkit-animation-delay: 2646ms;
            animation-delay: 2646ms;
  }
  .circle-container:nth-child(17) {
    width: 1px;
    height: 1px;
    -webkit-animation-name: move-frames-17;
            animation-name: move-frames-17;
    -webkit-animation-duration: 31911ms;
            animation-duration: 31911ms;
    -webkit-animation-delay: 14894ms;
            animation-delay: 14894ms;
  }
  @-webkit-keyframes move-frames-17 {
    from {
      transform: translate3d(86vw, 109vh, 0);
    }
    to {
      transform: translate3d(6vw, -110vh, 0);
    }
  }
  @keyframes move-frames-17 {
    from {
      transform: translate3d(86vw, 109vh, 0);
    }
    to {
      transform: translate3d(6vw, -110vh, 0);
    }
  }
  .circle-container:nth-child(17) .circle {
    -webkit-animation-delay: 35ms;
            animation-delay: 35ms;
  }
  .circle-container:nth-child(18) {
    width: 4px;
    height: 4px;
    -webkit-animation-name: move-frames-18;
            animation-name: move-frames-18;
    -webkit-animation-duration: 28236ms;
            animation-duration: 28236ms;
    -webkit-animation-delay: 3323ms;
            animation-delay: 3323ms;
  }
  @-webkit-keyframes move-frames-18 {
    from {
      transform: translate3d(63vw, 101vh, 0);
    }
    to {
      transform: translate3d(87vw, -113vh, 0);
    }
  }
  @keyframes move-frames-18 {
    from {
      transform: translate3d(63vw, 101vh, 0);
    }
    to {
      transform: translate3d(87vw, -113vh, 0);
    }
  }
  .circle-container:nth-child(18) .circle {
    -webkit-animation-delay: 831ms;
            animation-delay: 831ms;
  }
  .circle-container:nth-child(19) {
    width: 7px;
    height: 7px;
    -webkit-animation-name: move-frames-19;
            animation-name: move-frames-19;
    -webkit-animation-duration: 35757ms;
            animation-duration: 35757ms;
    -webkit-animation-delay: 25300ms;
            animation-delay: 25300ms;
  }
  @-webkit-keyframes move-frames-19 {
    from {
      transform: translate3d(35vw, 103vh, 0);
    }
    to {
      transform: translate3d(83vw, -124vh, 0);
    }
  }
  @keyframes move-frames-19 {
    from {
      transform: translate3d(35vw, 103vh, 0);
    }
    to {
      transform: translate3d(83vw, -124vh, 0);
    }
  }
  .circle-container:nth-child(19) .circle {
    -webkit-animation-delay: 276ms;
            animation-delay: 276ms;
  }
  .circle-container:nth-child(20) {
    width: 2px;
    height: 2px;
    -webkit-animation-name: move-frames-20;
            animation-name: move-frames-20;
    -webkit-animation-duration: 36356ms;
            animation-duration: 36356ms;
    -webkit-animation-delay: 10768ms;
            animation-delay: 10768ms;
  }
  @-webkit-keyframes move-frames-20 {
    from {
      transform: translate3d(94vw, 103vh, 0);
    }
    to {
      transform: translate3d(13vw, -129vh, 0);
    }
  }
  @keyframes move-frames-20 {
    from {
      transform: translate3d(94vw, 103vh, 0);
    }
    to {
      transform: translate3d(13vw, -129vh, 0);
    }
  }
  .circle-container:nth-child(20) .circle {
    -webkit-animation-delay: 751ms;
            animation-delay: 751ms;
  }
  .circle-container:nth-child(21) {
    width: 6px;
    height: 6px;
    -webkit-animation-name: move-frames-21;
            animation-name: move-frames-21;
    -webkit-animation-duration: 29789ms;
            animation-duration: 29789ms;
    -webkit-animation-delay: 16127ms;
            animation-delay: 16127ms;
  }
  @-webkit-keyframes move-frames-21 {
    from {
      transform: translate3d(52vw, 107vh, 0);
    }
    to {
      transform: translate3d(17vw, -134vh, 0);
    }
  }
  @keyframes move-frames-21 {
    from {
      transform: translate3d(52vw, 107vh, 0);
    }
    to {
      transform: translate3d(17vw, -134vh, 0);
    }
  }
  .circle-container:nth-child(21) .circle {
    -webkit-animation-delay: 1653ms;
            animation-delay: 1653ms;
  }
  .circle-container:nth-child(22) {
    width: 7px;
    height: 7px;
    -webkit-animation-name: move-frames-22;
            animation-name: move-frames-22;
    -webkit-animation-duration: 30035ms;
            animation-duration: 30035ms;
    -webkit-animation-delay: 20415ms;
            animation-delay: 20415ms;
  }
  @-webkit-keyframes move-frames-22 {
    from {
      transform: translate3d(79vw, 102vh, 0);
    }
    to {
      transform: translate3d(13vw, -128vh, 0);
    }
  }
  @keyframes move-frames-22 {
    from {
      transform: translate3d(79vw, 102vh, 0);
    }
    to {
      transform: translate3d(13vw, -128vh, 0);
    }
  }
  .circle-container:nth-child(22) .circle {
    -webkit-animation-delay: 942ms;
            animation-delay: 942ms;
  }
  .circle-container:nth-child(23) {
    width: 1px;
    height: 1px;
    -webkit-animation-name: move-frames-23;
            animation-name: move-frames-23;
    -webkit-animation-duration: 34797ms;
            animation-duration: 34797ms;
    -webkit-animation-delay: 30649ms;
            animation-delay: 30649ms;
  }
  @-webkit-keyframes move-frames-23 {
    from {
      transform: translate3d(21vw, 104vh, 0);
    }
    to {
      transform: translate3d(23vw, -123vh, 0);
    }
  }
  @keyframes move-frames-23 {
    from {
      transform: translate3d(21vw, 104vh, 0);
    }
    to {
      transform: translate3d(23vw, -123vh, 0);
    }
  }
  .circle-container:nth-child(23) .circle {
    -webkit-animation-delay: 2304ms;
            animation-delay: 2304ms;
  }
  .circle-container:nth-child(24) {
    width: 8px;
    height: 8px;
    -webkit-animation-name: move-frames-24;
            animation-name: move-frames-24;
    -webkit-animation-duration: 33396ms;
            animation-duration: 33396ms;
    -webkit-animation-delay: 25755ms;
            animation-delay: 25755ms;
  }
  @-webkit-keyframes move-frames-24 {
    from {
      transform: translate3d(71vw, 106vh, 0);
    }
    to {
      transform: translate3d(15vw, -124vh, 0);
    }
  }
  @keyframes move-frames-24 {
    from {
      transform: translate3d(71vw, 106vh, 0);
    }
    to {
      transform: translate3d(15vw, -124vh, 0);
    }
  }
  .circle-container:nth-child(24) .circle {
    -webkit-animation-delay: 1047ms;
            animation-delay: 1047ms;
  }
  .circle-container:nth-child(25) {
    width: 2px;
    height: 2px;
    -webkit-animation-name: move-frames-25;
            animation-name: move-frames-25;
    -webkit-animation-duration: 35341ms;
            animation-duration: 35341ms;
    -webkit-animation-delay: 30256ms;
            animation-delay: 30256ms;
  }
  @-webkit-keyframes move-frames-25 {
    from {
      transform: translate3d(61vw, 110vh, 0);
    }
    to {
      transform: translate3d(23vw, -130vh, 0);
    }
  }
  @keyframes move-frames-25 {
    from {
      transform: translate3d(61vw, 110vh, 0);
    }
    to {
      transform: translate3d(23vw, -130vh, 0);
    }
  }
  .circle-container:nth-child(25) .circle {
    -webkit-animation-delay: 3157ms;
            animation-delay: 3157ms;
  }
  .circle-container:nth-child(26) {
    width: 5px;
    height: 5px;
    -webkit-animation-name: move-frames-26;
            animation-name: move-frames-26;
    -webkit-animation-duration: 31796ms;
            animation-duration: 31796ms;
    -webkit-animation-delay: 9667ms;
            animation-delay: 9667ms;
  }
  @-webkit-keyframes move-frames-26 {
    from {
      transform: translate3d(62vw, 109vh, 0);
    }
    to {
      transform: translate3d(65vw, -125vh, 0);
    }
  }
  @keyframes move-frames-26 {
    from {
      transform: translate3d(62vw, 109vh, 0);
    }
    to {
      transform: translate3d(65vw, -125vh, 0);
    }
  }
  .circle-container:nth-child(26) .circle {
    -webkit-animation-delay: 676ms;
            animation-delay: 676ms;
  }
  .circle-container:nth-child(27) {
    width: 6px;
    height: 6px;
    -webkit-animation-name: move-frames-27;
            animation-name: move-frames-27;
    -webkit-animation-duration: 28883ms;
            animation-duration: 28883ms;
    -webkit-animation-delay: 7532ms;
            animation-delay: 7532ms;
  }
  @-webkit-keyframes move-frames-27 {
    from {
      transform: translate3d(18vw, 108vh, 0);
    }
    to {
      transform: translate3d(10vw, -113vh, 0);
    }
  }
  @keyframes move-frames-27 {
    from {
      transform: translate3d(18vw, 108vh, 0);
    }
    to {
      transform: translate3d(10vw, -113vh, 0);
    }
  }
  .circle-container:nth-child(27) .circle {
    -webkit-animation-delay: 3056ms;
            animation-delay: 3056ms;
  }
  .circle-container:nth-child(28) {
    width: 3px;
    height: 3px;
    -webkit-animation-name: move-frames-28;
            animation-name: move-frames-28;
    -webkit-animation-duration: 35515ms;
            animation-duration: 35515ms;
    -webkit-animation-delay: 13724ms;
            animation-delay: 13724ms;
  }
  @-webkit-keyframes move-frames-28 {
    from {
      transform: translate3d(21vw, 107vh, 0);
    }
    to {
      transform: translate3d(72vw, -126vh, 0);
    }
  }
  @keyframes move-frames-28 {
    from {
      transform: translate3d(21vw, 107vh, 0);
    }
    to {
      transform: translate3d(72vw, -126vh, 0);
    }
  }
  .circle-container:nth-child(28) .circle {
    -webkit-animation-delay: 1180ms;
            animation-delay: 1180ms;
  }
  .circle-container:nth-child(29) {
    width: 1px;
    height: 1px;
    -webkit-animation-name: move-frames-29;
            animation-name: move-frames-29;
    -webkit-animation-duration: 36859ms;
            animation-duration: 36859ms;
    -webkit-animation-delay: 23968ms;
            animation-delay: 23968ms;
  }
  @-webkit-keyframes move-frames-29 {
    from {
      transform: translate3d(56vw, 108vh, 0);
    }
    to {
      transform: translate3d(82vw, -110vh, 0);
    }
  }
  @keyframes move-frames-29 {
    from {
      transform: translate3d(56vw, 108vh, 0);
    }
    to {
      transform: translate3d(82vw, -110vh, 0);
    }
  }
  .circle-container:nth-child(29) .circle {
    -webkit-animation-delay: 2236ms;
            animation-delay: 2236ms;
  }
  .circle-container:nth-child(30) {
    width: 7px;
    height: 7px;
    -webkit-animation-name: move-frames-30;
            animation-name: move-frames-30;
    -webkit-animation-duration: 34348ms;
            animation-duration: 34348ms;
    -webkit-animation-delay: 12895ms;
            animation-delay: 12895ms;
  }
  @-webkit-keyframes move-frames-30 {
    from {
      transform: translate3d(55vw, 102vh, 0);
    }
    to {
      transform: translate3d(93vw, -123vh, 0);
    }
  }
  @keyframes move-frames-30 {
    from {
      transform: translate3d(55vw, 102vh, 0);
    }
    to {
      transform: translate3d(93vw, -123vh, 0);
    }
  }
  .circle-container:nth-child(30) .circle {
    -webkit-animation-delay: 1105ms;
            animation-delay: 1105ms;
  }
  .circle-container:nth-child(31) {
    width: 6px;
    height: 6px;
    -webkit-animation-name: move-frames-31;
            animation-name: move-frames-31;
    -webkit-animation-duration: 33609ms;
            animation-duration: 33609ms;
    -webkit-animation-delay: 16700ms;
            animation-delay: 16700ms;
  }
  @-webkit-keyframes move-frames-31 {
    from {
      transform: translate3d(66vw, 105vh, 0);
    }
    to {
      transform: translate3d(18vw, -123vh, 0);
    }
  }
  @keyframes move-frames-31 {
    from {
      transform: translate3d(66vw, 105vh, 0);
    }
    to {
      transform: translate3d(18vw, -123vh, 0);
    }
  }
  .circle-container:nth-child(31) .circle {
    -webkit-animation-delay: 3303ms;
            animation-delay: 3303ms;
  }
  .circle-container:nth-child(32) {
    width: 6px;
    height: 6px;
    -webkit-animation-name: move-frames-32;
            animation-name: move-frames-32;
    -webkit-animation-duration: 36119ms;
            animation-duration: 36119ms;
    -webkit-animation-delay: 29931ms;
            animation-delay: 29931ms;
  }
  @-webkit-keyframes move-frames-32 {
    from {
      transform: translate3d(8vw, 102vh, 0);
    }
    to {
      transform: translate3d(86vw, -106vh, 0);
    }
  }
  @keyframes move-frames-32 {
    from {
      transform: translate3d(8vw, 102vh, 0);
    }
    to {
      transform: translate3d(86vw, -106vh, 0);
    }
  }
  .circle-container:nth-child(32) .circle {
    -webkit-animation-delay: 1714ms;
            animation-delay: 1714ms;
  }
  .circle-container:nth-child(33) {
    width: 5px;
    height: 5px;
    -webkit-animation-name: move-frames-33;
            animation-name: move-frames-33;
    -webkit-animation-duration: 33425ms;
            animation-duration: 33425ms;
    -webkit-animation-delay: 9687ms;
            animation-delay: 9687ms;
  }
  @-webkit-keyframes move-frames-33 {
    from {
      transform: translate3d(65vw, 104vh, 0);
    }
    to {
      transform: translate3d(56vw, -119vh, 0);
    }
  }
  @keyframes move-frames-33 {
    from {
      transform: translate3d(65vw, 104vh, 0);
    }
    to {
      transform: translate3d(56vw, -119vh, 0);
    }
  }
  .circle-container:nth-child(33) .circle {
    -webkit-animation-delay: 1704ms;
            animation-delay: 1704ms;
  }
  .circle-container:nth-child(34) {
    width: 8px;
    height: 8px;
    -webkit-animation-name: move-frames-34;
            animation-name: move-frames-34;
    -webkit-animation-duration: 34610ms;
            animation-duration: 34610ms;
    -webkit-animation-delay: 5056ms;
            animation-delay: 5056ms;
  }
  @-webkit-keyframes move-frames-34 {
    from {
      transform: translate3d(37vw, 103vh, 0);
    }
    to {
      transform: translate3d(13vw, -116vh, 0);
    }
  }
  @keyframes move-frames-34 {
    from {
      transform: translate3d(37vw, 103vh, 0);
    }
    to {
      transform: translate3d(13vw, -116vh, 0);
    }
  }
  .circle-container:nth-child(34) .circle {
    -webkit-animation-delay: 2910ms;
            animation-delay: 2910ms;
  }
  .circle-container:nth-child(35) {
    width: 1px;
    height: 1px;
    -webkit-animation-name: move-frames-35;
            animation-name: move-frames-35;
    -webkit-animation-duration: 34130ms;
            animation-duration: 34130ms;
    -webkit-animation-delay: 26737ms;
            animation-delay: 26737ms;
  }
  @-webkit-keyframes move-frames-35 {
    from {
      transform: translate3d(92vw, 106vh, 0);
    }
    to {
      transform: translate3d(50vw, -134vh, 0);
    }
  }
  @keyframes move-frames-35 {
    from {
      transform: translate3d(92vw, 106vh, 0);
    }
    to {
      transform: translate3d(50vw, -134vh, 0);
    }
  }
  .circle-container:nth-child(35) .circle {
    -webkit-animation-delay: 1795ms;
            animation-delay: 1795ms;
  }
  .circle-container:nth-child(36) {
    width: 1px;
    height: 1px;
    -webkit-animation-name: move-frames-36;
            animation-name: move-frames-36;
    -webkit-animation-duration: 31103ms;
            animation-duration: 31103ms;
    -webkit-animation-delay: 1478ms;
            animation-delay: 1478ms;
  }
  @-webkit-keyframes move-frames-36 {
    from {
      transform: translate3d(79vw, 105vh, 0);
    }
    to {
      transform: translate3d(14vw, -135vh, 0);
    }
  }
  @keyframes move-frames-36 {
    from {
      transform: translate3d(79vw, 105vh, 0);
    }
    to {
      transform: translate3d(14vw, -135vh, 0);
    }
  }
  .circle-container:nth-child(36) .circle {
    -webkit-animation-delay: 2295ms;
            animation-delay: 2295ms;
  }
  .circle-container:nth-child(37) {
    width: 8px;
    height: 8px;
    -webkit-animation-name: move-frames-37;
            animation-name: move-frames-37;
    -webkit-animation-duration: 32345ms;
            animation-duration: 32345ms;
    -webkit-animation-delay: 10231ms;
            animation-delay: 10231ms;
  }
  @-webkit-keyframes move-frames-37 {
    from {
      transform: translate3d(59vw, 108vh, 0);
    }
    to {
      transform: translate3d(9vw, -130vh, 0);
    }
  }
  @keyframes move-frames-37 {
    from {
      transform: translate3d(59vw, 108vh, 0);
    }
    to {
      transform: translate3d(9vw, -130vh, 0);
    }
  }
  .circle-container:nth-child(37) .circle {
    -webkit-animation-delay: 49ms;
            animation-delay: 49ms;
  }
  .circle-container:nth-child(38) {
    width: 1px;
    height: 1px;
    -webkit-animation-name: move-frames-38;
            animation-name: move-frames-38;
    -webkit-animation-duration: 34002ms;
            animation-duration: 34002ms;
    -webkit-animation-delay: 21341ms;
            animation-delay: 21341ms;
  }
  @-webkit-keyframes move-frames-38 {
    from {
      transform: translate3d(26vw, 102vh, 0);
    }
    to {
      transform: translate3d(58vw, -125vh, 0);
    }
  }
  @keyframes move-frames-38 {
    from {
      transform: translate3d(26vw, 102vh, 0);
    }
    to {
      transform: translate3d(58vw, -125vh, 0);
    }
  }
  .circle-container:nth-child(38) .circle {
    -webkit-animation-delay: 3389ms;
            animation-delay: 3389ms;
  }
  .circle-container:nth-child(39) {
    width: 7px;
    height: 7px;
    -webkit-animation-name: move-frames-39;
            animation-name: move-frames-39;
    -webkit-animation-duration: 32152ms;
            animation-duration: 32152ms;
    -webkit-animation-delay: 5653ms;
            animation-delay: 5653ms;
  }
  @-webkit-keyframes move-frames-39 {
    from {
      transform: translate3d(21vw, 106vh, 0);
    }
    to {
      transform: translate3d(72vw, -122vh, 0);
    }
  }
  @keyframes move-frames-39 {
    from {
      transform: translate3d(21vw, 106vh, 0);
    }
    to {
      transform: translate3d(72vw, -122vh, 0);
    }
  }
  .circle-container:nth-child(39) .circle {
    -webkit-animation-delay: 2510ms;
            animation-delay: 2510ms;
  }
  .circle-container:nth-child(40) {
    width: 1px;
    height: 1px;
    -webkit-animation-name: move-frames-40;
            animation-name: move-frames-40;
    -webkit-animation-duration: 31588ms;
            animation-duration: 31588ms;
    -webkit-animation-delay: 23114ms;
            animation-delay: 23114ms;
  }
  @-webkit-keyframes move-frames-40 {
    from {
      transform: translate3d(89vw, 108vh, 0);
    }
    to {
      transform: translate3d(28vw, -135vh, 0);
    }
  }
  @keyframes move-frames-40 {
    from {
      transform: translate3d(89vw, 108vh, 0);
    }
    to {
      transform: translate3d(28vw, -135vh, 0);
    }
  }
  .circle-container:nth-child(40) .circle {
    -webkit-animation-delay: 3313ms;
            animation-delay: 3313ms;
  }
  .circle-container:nth-child(41) {
    width: 3px;
    height: 3px;
    -webkit-animation-name: move-frames-41;
            animation-name: move-frames-41;
    -webkit-animation-duration: 34262ms;
            animation-duration: 34262ms;
    -webkit-animation-delay: 25384ms;
            animation-delay: 25384ms;
  }
  @-webkit-keyframes move-frames-41 {
    from {
      transform: translate3d(37vw, 104vh, 0);
    }
    to {
      transform: translate3d(23vw, -113vh, 0);
    }
  }
  @keyframes move-frames-41 {
    from {
      transform: translate3d(37vw, 104vh, 0);
    }
    to {
      transform: translate3d(23vw, -113vh, 0);
    }
  }
  .circle-container:nth-child(41) .circle {
    -webkit-animation-delay: 3337ms;
            animation-delay: 3337ms;
  }
  .circle-container:nth-child(42) {
    width: 6px;
    height: 6px;
    -webkit-animation-name: move-frames-42;
            animation-name: move-frames-42;
    -webkit-animation-duration: 32280ms;
            animation-duration: 32280ms;
    -webkit-animation-delay: 21538ms;
            animation-delay: 21538ms;
  }
  @-webkit-keyframes move-frames-42 {
    from {
      transform: translate3d(14vw, 106vh, 0);
    }
    to {
      transform: translate3d(60vw, -122vh, 0);
    }
  }
  @keyframes move-frames-42 {
    from {
      transform: translate3d(14vw, 106vh, 0);
    }
    to {
      transform: translate3d(60vw, -122vh, 0);
    }
  }
  .circle-container:nth-child(42) .circle {
    -webkit-animation-delay: 3168ms;
            animation-delay: 3168ms;
  }
  .circle-container:nth-child(43) {
    width: 5px;
    height: 5px;
    -webkit-animation-name: move-frames-43;
            animation-name: move-frames-43;
    -webkit-animation-duration: 34957ms;
            animation-duration: 34957ms;
    -webkit-animation-delay: 30215ms;
            animation-delay: 30215ms;
  }
  @-webkit-keyframes move-frames-43 {
    from {
      transform: translate3d(50vw, 105vh, 0);
    }
    to {
      transform: translate3d(58vw, -134vh, 0);
    }
  }
  @keyframes move-frames-43 {
    from {
      transform: translate3d(50vw, 105vh, 0);
    }
    to {
      transform: translate3d(58vw, -134vh, 0);
    }
  }
  .circle-container:nth-child(43) .circle {
    -webkit-animation-delay: 2373ms;
            animation-delay: 2373ms;
  }
  .circle-container:nth-child(44) {
    width: 7px;
    height: 7px;
    -webkit-animation-name: move-frames-44;
            animation-name: move-frames-44;
    -webkit-animation-duration: 28496ms;
            animation-duration: 28496ms;
    -webkit-animation-delay: 8319ms;
            animation-delay: 8319ms;
  }
  @-webkit-keyframes move-frames-44 {
    from {
      transform: translate3d(20vw, 109vh, 0);
    }
    to {
      transform: translate3d(48vw, -139vh, 0);
    }
  }
  @keyframes move-frames-44 {
    from {
      transform: translate3d(20vw, 109vh, 0);
    }
    to {
      transform: translate3d(48vw, -139vh, 0);
    }
  }
  .circle-container:nth-child(44) .circle {
    -webkit-animation-delay: 378ms;
            animation-delay: 378ms;
  }
  .circle-container:nth-child(45) {
    width: 3px;
    height: 3px;
    -webkit-animation-name: move-frames-45;
            animation-name: move-frames-45;
    -webkit-animation-duration: 30195ms;
            animation-duration: 30195ms;
    -webkit-animation-delay: 1144ms;
            animation-delay: 1144ms;
  }
  @-webkit-keyframes move-frames-45 {
    from {
      transform: translate3d(40vw, 104vh, 0);
    }
    to {
      transform: translate3d(8vw, -109vh, 0);
    }
  }
  @keyframes move-frames-45 {
    from {
      transform: translate3d(40vw, 104vh, 0);
    }
    to {
      transform: translate3d(8vw, -109vh, 0);
    }
  }
  .circle-container:nth-child(45) .circle {
    -webkit-animation-delay: 2432ms;
            animation-delay: 2432ms;
  }
  .circle-container:nth-child(46) {
    width: 5px;
    height: 5px;
    -webkit-animation-name: move-frames-46;
            animation-name: move-frames-46;
    -webkit-animation-duration: 36581ms;
            animation-duration: 36581ms;
    -webkit-animation-delay: 25312ms;
            animation-delay: 25312ms;
  }
  @-webkit-keyframes move-frames-46 {
    from {
      transform: translate3d(84vw, 108vh, 0);
    }
    to {
      transform: translate3d(10vw, -115vh, 0);
    }
  }
  @keyframes move-frames-46 {
    from {
      transform: translate3d(84vw, 108vh, 0);
    }
    to {
      transform: translate3d(10vw, -115vh, 0);
    }
  }
  .circle-container:nth-child(46) .circle {
    -webkit-animation-delay: 3170ms;
            animation-delay: 3170ms;
  }
  .circle-container:nth-child(47) {
    width: 8px;
    height: 8px;
    -webkit-animation-name: move-frames-47;
            animation-name: move-frames-47;
    -webkit-animation-duration: 35731ms;
            animation-duration: 35731ms;
    -webkit-animation-delay: 605ms;
            animation-delay: 605ms;
  }
  @-webkit-keyframes move-frames-47 {
    from {
      transform: translate3d(46vw, 104vh, 0);
    }
    to {
      transform: translate3d(16vw, -114vh, 0);
    }
  }
  @keyframes move-frames-47 {
    from {
      transform: translate3d(46vw, 104vh, 0);
    }
    to {
      transform: translate3d(16vw, -114vh, 0);
    }
  }
  .circle-container:nth-child(47) .circle {
    -webkit-animation-delay: 3897ms;
            animation-delay: 3897ms;
  }
  .circle-container:nth-child(48) {
    width: 2px;
    height: 2px;
    -webkit-animation-name: move-frames-48;
            animation-name: move-frames-48;
    -webkit-animation-duration: 28304ms;
            animation-duration: 28304ms;
    -webkit-animation-delay: 29802ms;
            animation-delay: 29802ms;
  }
  @-webkit-keyframes move-frames-48 {
    from {
      transform: translate3d(19vw, 105vh, 0);
    }
    to {
      transform: translate3d(16vw, -128vh, 0);
    }
  }
  @keyframes move-frames-48 {
    from {
      transform: translate3d(19vw, 105vh, 0);
    }
    to {
      transform: translate3d(16vw, -128vh, 0);
    }
  }
  .circle-container:nth-child(48) .circle {
    -webkit-animation-delay: 3733ms;
            animation-delay: 3733ms;
  }
  .circle-container:nth-child(49) {
    width: 7px;
    height: 7px;
    -webkit-animation-name: move-frames-49;
            animation-name: move-frames-49;
    -webkit-animation-duration: 29766ms;
            animation-duration: 29766ms;
    -webkit-animation-delay: 35633ms;
            animation-delay: 35633ms;
  }
  @-webkit-keyframes move-frames-49 {
    from {
      transform: translate3d(9vw, 103vh, 0);
    }
    to {
      transform: translate3d(44vw, -125vh, 0);
    }
  }
  @keyframes move-frames-49 {
    from {
      transform: translate3d(9vw, 103vh, 0);
    }
    to {
      transform: translate3d(44vw, -125vh, 0);
    }
  }
  .circle-container:nth-child(49) .circle {
    -webkit-animation-delay: 63ms;
            animation-delay: 63ms;
  }
  .circle-container:nth-child(50) {
    width: 5px;
    height: 5px;
    -webkit-animation-name: move-frames-50;
            animation-name: move-frames-50;
    -webkit-animation-duration: 32715ms;
            animation-duration: 32715ms;
    -webkit-animation-delay: 35280ms;
            animation-delay: 35280ms;
  }
  @-webkit-keyframes move-frames-50 {
    from {
      transform: translate3d(54vw, 110vh, 0);
    }
    to {
      transform: translate3d(57vw, -135vh, 0);
    }
  }
  @keyframes move-frames-50 {
    from {
      transform: translate3d(54vw, 110vh, 0);
    }
    to {
      transform: translate3d(57vw, -135vh, 0);
    }
  }
  .circle-container:nth-child(50) .circle {
    -webkit-animation-delay: 3406ms;
            animation-delay: 3406ms;
  }
  .circle-container:nth-child(51) {
    width: 7px;
    height: 7px;
    -webkit-animation-name: move-frames-51;
            animation-name: move-frames-51;
    -webkit-animation-duration: 33683ms;
            animation-duration: 33683ms;
    -webkit-animation-delay: 35936ms;
            animation-delay: 35936ms;
  }
  @-webkit-keyframes move-frames-51 {
    from {
      transform: translate3d(30vw, 103vh, 0);
    }
    to {
      transform: translate3d(83vw, -128vh, 0);
    }
  }
  @keyframes move-frames-51 {
    from {
      transform: translate3d(30vw, 103vh, 0);
    }
    to {
      transform: translate3d(83vw, -128vh, 0);
    }
  }
  .circle-container:nth-child(51) .circle {
    -webkit-animation-delay: 2499ms;
            animation-delay: 2499ms;
  }
  .circle-container:nth-child(52) {
    width: 4px;
    height: 4px;
    -webkit-animation-name: move-frames-52;
            animation-name: move-frames-52;
    -webkit-animation-duration: 32612ms;
            animation-duration: 32612ms;
    -webkit-animation-delay: 14401ms;
            animation-delay: 14401ms;
  }
  @-webkit-keyframes move-frames-52 {
    from {
      transform: translate3d(58vw, 104vh, 0);
    }
    to {
      transform: translate3d(67vw, -123vh, 0);
    }
  }
  @keyframes move-frames-52 {
    from {
      transform: translate3d(58vw, 104vh, 0);
    }
    to {
      transform: translate3d(67vw, -123vh, 0);
    }
  }
  .circle-container:nth-child(52) .circle {
    -webkit-animation-delay: 3526ms;
            animation-delay: 3526ms;
  }
  .circle-container:nth-child(53) {
    width: 4px;
    height: 4px;
    -webkit-animation-name: move-frames-53;
            animation-name: move-frames-53;
    -webkit-animation-duration: 36956ms;
            animation-duration: 36956ms;
    -webkit-animation-delay: 35782ms;
            animation-delay: 35782ms;
  }
  @-webkit-keyframes move-frames-53 {
    from {
      transform: translate3d(81vw, 104vh, 0);
    }
    to {
      transform: translate3d(6vw, -130vh, 0);
    }
  }
  @keyframes move-frames-53 {
    from {
      transform: translate3d(81vw, 104vh, 0);
    }
    to {
      transform: translate3d(6vw, -130vh, 0);
    }
  }
  .circle-container:nth-child(53) .circle {
    -webkit-animation-delay: 2572ms;
            animation-delay: 2572ms;
  }
  .circle-container:nth-child(54) {
    width: 3px;
    height: 3px;
    -webkit-animation-name: move-frames-54;
            animation-name: move-frames-54;
    -webkit-animation-duration: 35205ms;
            animation-duration: 35205ms;
    -webkit-animation-delay: 13276ms;
            animation-delay: 13276ms;
  }
  @-webkit-keyframes move-frames-54 {
    from {
      transform: translate3d(1vw, 106vh, 0);
    }
    to {
      transform: translate3d(41vw, -125vh, 0);
    }
  }
  @keyframes move-frames-54 {
    from {
      transform: translate3d(1vw, 106vh, 0);
    }
    to {
      transform: translate3d(41vw, -125vh, 0);
    }
  }
  .circle-container:nth-child(54) .circle {
    -webkit-animation-delay: 2848ms;
            animation-delay: 2848ms;
  }
  .circle-container:nth-child(55) {
    width: 6px;
    height: 6px;
    -webkit-animation-name: move-frames-55;
            animation-name: move-frames-55;
    -webkit-animation-duration: 36834ms;
            animation-duration: 36834ms;
    -webkit-animation-delay: 11256ms;
            animation-delay: 11256ms;
  }
  @-webkit-keyframes move-frames-55 {
    from {
      transform: translate3d(24vw, 102vh, 0);
    }
    to {
      transform: translate3d(70vw, -128vh, 0);
    }
  }
  @keyframes move-frames-55 {
    from {
      transform: translate3d(24vw, 102vh, 0);
    }
    to {
      transform: translate3d(70vw, -128vh, 0);
    }
  }
  .circle-container:nth-child(55) .circle {
    -webkit-animation-delay: 2313ms;
            animation-delay: 2313ms;
  }
  .circle-container:nth-child(56) {
    width: 6px;
    height: 6px;
    -webkit-animation-name: move-frames-56;
            animation-name: move-frames-56;
    -webkit-animation-duration: 29511ms;
            animation-duration: 29511ms;
    -webkit-animation-delay: 3131ms;
            animation-delay: 3131ms;
  }
  @-webkit-keyframes move-frames-56 {
    from {
      transform: translate3d(39vw, 109vh, 0);
    }
    to {
      transform: translate3d(53vw, -133vh, 0);
    }
  }
  @keyframes move-frames-56 {
    from {
      transform: translate3d(39vw, 109vh, 0);
    }
    to {
      transform: translate3d(53vw, -133vh, 0);
    }
  }
  .circle-container:nth-child(56) .circle {
    -webkit-animation-delay: 2201ms;
            animation-delay: 2201ms;
  }
  .circle-container:nth-child(57) {
    width: 6px;
    height: 6px;
    -webkit-animation-name: move-frames-57;
            animation-name: move-frames-57;
    -webkit-animation-duration: 34812ms;
            animation-duration: 34812ms;
    -webkit-animation-delay: 399ms;
            animation-delay: 399ms;
  }
  @-webkit-keyframes move-frames-57 {
    from {
      transform: translate3d(5vw, 106vh, 0);
    }
    to {
      transform: translate3d(52vw, -114vh, 0);
    }
  }
  @keyframes move-frames-57 {
    from {
      transform: translate3d(5vw, 106vh, 0);
    }
    to {
      transform: translate3d(52vw, -114vh, 0);
    }
  }
  .circle-container:nth-child(57) .circle {
    -webkit-animation-delay: 398ms;
            animation-delay: 398ms;
  }
  .circle-container:nth-child(58) {
    width: 7px;
    height: 7px;
    -webkit-animation-name: move-frames-58;
            animation-name: move-frames-58;
    -webkit-animation-duration: 30108ms;
            animation-duration: 30108ms;
    -webkit-animation-delay: 30658ms;
            animation-delay: 30658ms;
  }
  @-webkit-keyframes move-frames-58 {
    from {
      transform: translate3d(81vw, 103vh, 0);
    }
    to {
      transform: translate3d(28vw, -126vh, 0);
    }
  }
  @keyframes move-frames-58 {
    from {
      transform: translate3d(81vw, 103vh, 0);
    }
    to {
      transform: translate3d(28vw, -126vh, 0);
    }
  }
  .circle-container:nth-child(58) .circle {
    -webkit-animation-delay: 438ms;
            animation-delay: 438ms;
  }
  .circle-container:nth-child(59) {
    width: 1px;
    height: 1px;
    -webkit-animation-name: move-frames-59;
            animation-name: move-frames-59;
    -webkit-animation-duration: 36628ms;
            animation-duration: 36628ms;
    -webkit-animation-delay: 9998ms;
            animation-delay: 9998ms;
  }
  @-webkit-keyframes move-frames-59 {
    from {
      transform: translate3d(58vw, 103vh, 0);
    }
    to {
      transform: translate3d(83vw, -128vh, 0);
    }
  }
  @keyframes move-frames-59 {
    from {
      transform: translate3d(58vw, 103vh, 0);
    }
    to {
      transform: translate3d(83vw, -128vh, 0);
    }
  }
  .circle-container:nth-child(59) .circle {
    -webkit-animation-delay: 724ms;
            animation-delay: 724ms;
  }
  .circle-container:nth-child(60) {
    width: 4px;
    height: 4px;
    -webkit-animation-name: move-frames-60;
            animation-name: move-frames-60;
    -webkit-animation-duration: 36119ms;
            animation-duration: 36119ms;
    -webkit-animation-delay: 32117ms;
            animation-delay: 32117ms;
  }
  @-webkit-keyframes move-frames-60 {
    from {
      transform: translate3d(47vw, 108vh, 0);
    }
    to {
      transform: translate3d(56vw, -124vh, 0);
    }
  }
  @keyframes move-frames-60 {
    from {
      transform: translate3d(47vw, 108vh, 0);
    }
    to {
      transform: translate3d(56vw, -124vh, 0);
    }
  }
  .circle-container:nth-child(60) .circle {
    -webkit-animation-delay: 3564ms;
            animation-delay: 3564ms;
  }
  .circle-container:nth-child(61) {
    width: 2px;
    height: 2px;
    -webkit-animation-name: move-frames-61;
            animation-name: move-frames-61;
    -webkit-animation-duration: 33492ms;
            animation-duration: 33492ms;
    -webkit-animation-delay: 11748ms;
            animation-delay: 11748ms;
  }
  @-webkit-keyframes move-frames-61 {
    from {
      transform: translate3d(83vw, 101vh, 0);
    }
    to {
      transform: translate3d(74vw, -116vh, 0);
    }
  }
  @keyframes move-frames-61 {
    from {
      transform: translate3d(83vw, 101vh, 0);
    }
    to {
      transform: translate3d(74vw, -116vh, 0);
    }
  }
  .circle-container:nth-child(61) .circle {
    -webkit-animation-delay: 2361ms;
            animation-delay: 2361ms;
  }
  .circle-container:nth-child(62) {
    width: 3px;
    height: 3px;
    -webkit-animation-name: move-frames-62;
            animation-name: move-frames-62;
    -webkit-animation-duration: 30872ms;
            animation-duration: 30872ms;
    -webkit-animation-delay: 32581ms;
            animation-delay: 32581ms;
  }
  @-webkit-keyframes move-frames-62 {
    from {
      transform: translate3d(92vw, 103vh, 0);
    }
    to {
      transform: translate3d(66vw, -105vh, 0);
    }
  }
  @keyframes move-frames-62 {
    from {
      transform: translate3d(92vw, 103vh, 0);
    }
    to {
      transform: translate3d(66vw, -105vh, 0);
    }
  }
  .circle-container:nth-child(62) .circle {
    -webkit-animation-delay: 3051ms;
            animation-delay: 3051ms;
  }
  .circle-container:nth-child(63) {
    width: 7px;
    height: 7px;
    -webkit-animation-name: move-frames-63;
            animation-name: move-frames-63;
    -webkit-animation-duration: 34357ms;
            animation-duration: 34357ms;
    -webkit-animation-delay: 24048ms;
            animation-delay: 24048ms;
  }
  @-webkit-keyframes move-frames-63 {
    from {
      transform: translate3d(59vw, 108vh, 0);
    }
    to {
      transform: translate3d(58vw, -128vh, 0);
    }
  }
  @keyframes move-frames-63 {
    from {
      transform: translate3d(59vw, 108vh, 0);
    }
    to {
      transform: translate3d(58vw, -128vh, 0);
    }
  }
  .circle-container:nth-child(63) .circle {
    -webkit-animation-delay: 2168ms;
            animation-delay: 2168ms;
  }
  .circle-container:nth-child(64) {
    width: 2px;
    height: 2px;
    -webkit-animation-name: move-frames-64;
            animation-name: move-frames-64;
    -webkit-animation-duration: 32417ms;
            animation-duration: 32417ms;
    -webkit-animation-delay: 828ms;
            animation-delay: 828ms;
  }
  @-webkit-keyframes move-frames-64 {
    from {
      transform: translate3d(19vw, 106vh, 0);
    }
    to {
      transform: translate3d(50vw, -108vh, 0);
    }
  }
  @keyframes move-frames-64 {
    from {
      transform: translate3d(19vw, 106vh, 0);
    }
    to {
      transform: translate3d(50vw, -108vh, 0);
    }
  }
  .circle-container:nth-child(64) .circle {
    -webkit-animation-delay: 2349ms;
            animation-delay: 2349ms;
  }
  .circle-container:nth-child(65) {
    width: 5px;
    height: 5px;
    -webkit-animation-name: move-frames-65;
            animation-name: move-frames-65;
    -webkit-animation-duration: 35171ms;
            animation-duration: 35171ms;
    -webkit-animation-delay: 10641ms;
            animation-delay: 10641ms;
  }
  @-webkit-keyframes move-frames-65 {
    from {
      transform: translate3d(85vw, 105vh, 0);
    }
    to {
      transform: translate3d(25vw, -108vh, 0);
    }
  }
  @keyframes move-frames-65 {
    from {
      transform: translate3d(85vw, 105vh, 0);
    }
    to {
      transform: translate3d(25vw, -108vh, 0);
    }
  }
  .circle-container:nth-child(65) .circle {
    -webkit-animation-delay: 1671ms;
            animation-delay: 1671ms;
  }
  .circle-container:nth-child(66) {
    width: 6px;
    height: 6px;
    -webkit-animation-name: move-frames-66;
            animation-name: move-frames-66;
    -webkit-animation-duration: 31095ms;
            animation-duration: 31095ms;
    -webkit-animation-delay: 827ms;
            animation-delay: 827ms;
  }
  @-webkit-keyframes move-frames-66 {
    from {
      transform: translate3d(34vw, 105vh, 0);
    }
    to {
      transform: translate3d(59vw, -114vh, 0);
    }
  }
  @keyframes move-frames-66 {
    from {
      transform: translate3d(34vw, 105vh, 0);
    }
    to {
      transform: translate3d(59vw, -114vh, 0);
    }
  }
  .circle-container:nth-child(66) .circle {
    -webkit-animation-delay: 66ms;
            animation-delay: 66ms;
  }
  .circle-container:nth-child(67) {
    width: 6px;
    height: 6px;
    -webkit-animation-name: move-frames-67;
            animation-name: move-frames-67;
    -webkit-animation-duration: 34567ms;
            animation-duration: 34567ms;
    -webkit-animation-delay: 9188ms;
            animation-delay: 9188ms;
  }
  @-webkit-keyframes move-frames-67 {
    from {
      transform: translate3d(73vw, 105vh, 0);
    }
    to {
      transform: translate3d(6vw, -111vh, 0);
    }
  }
  @keyframes move-frames-67 {
    from {
      transform: translate3d(73vw, 105vh, 0);
    }
    to {
      transform: translate3d(6vw, -111vh, 0);
    }
  }
  .circle-container:nth-child(67) .circle {
    -webkit-animation-delay: 3979ms;
            animation-delay: 3979ms;
  }
  .circle-container:nth-child(68) {
    width: 5px;
    height: 5px;
    -webkit-animation-name: move-frames-68;
            animation-name: move-frames-68;
    -webkit-animation-duration: 35884ms;
            animation-duration: 35884ms;
    -webkit-animation-delay: 952ms;
            animation-delay: 952ms;
  }
  @-webkit-keyframes move-frames-68 {
    from {
      transform: translate3d(63vw, 108vh, 0);
    }
    to {
      transform: translate3d(57vw, -129vh, 0);
    }
  }
  @keyframes move-frames-68 {
    from {
      transform: translate3d(63vw, 108vh, 0);
    }
    to {
      transform: translate3d(57vw, -129vh, 0);
    }
  }
  .circle-container:nth-child(68) .circle {
    -webkit-animation-delay: 876ms;
            animation-delay: 876ms;
  }
  .circle-container:nth-child(69) {
    width: 5px;
    height: 5px;
    -webkit-animation-name: move-frames-69;
            animation-name: move-frames-69;
    -webkit-animation-duration: 34906ms;
            animation-duration: 34906ms;
    -webkit-animation-delay: 2388ms;
            animation-delay: 2388ms;
  }
  @-webkit-keyframes move-frames-69 {
    from {
      transform: translate3d(49vw, 103vh, 0);
    }
    to {
      transform: translate3d(3vw, -126vh, 0);
    }
  }
  @keyframes move-frames-69 {
    from {
      transform: translate3d(49vw, 103vh, 0);
    }
    to {
      transform: translate3d(3vw, -126vh, 0);
    }
  }
  .circle-container:nth-child(69) .circle {
    -webkit-animation-delay: 1287ms;
            animation-delay: 1287ms;
  }
  .circle-container:nth-child(70) {
    width: 6px;
    height: 6px;
    -webkit-animation-name: move-frames-70;
            animation-name: move-frames-70;
    -webkit-animation-duration: 34198ms;
            animation-duration: 34198ms;
    -webkit-animation-delay: 12733ms;
            animation-delay: 12733ms;
  }
  @-webkit-keyframes move-frames-70 {
    from {
      transform: translate3d(46vw, 110vh, 0);
    }
    to {
      transform: translate3d(70vw, -111vh, 0);
    }
  }
  @keyframes move-frames-70 {
    from {
      transform: translate3d(46vw, 110vh, 0);
    }
    to {
      transform: translate3d(70vw, -111vh, 0);
    }
  }
  .circle-container:nth-child(70) .circle {
    -webkit-animation-delay: 2621ms;
            animation-delay: 2621ms;
  }
  .circle-container:nth-child(71) {
    width: 3px;
    height: 3px;
    -webkit-animation-name: move-frames-71;
            animation-name: move-frames-71;
    -webkit-animation-duration: 30819ms;
            animation-duration: 30819ms;
    -webkit-animation-delay: 27644ms;
            animation-delay: 27644ms;
  }
  @-webkit-keyframes move-frames-71 {
    from {
      transform: translate3d(7vw, 105vh, 0);
    }
    to {
      transform: translate3d(57vw, -118vh, 0);
    }
  }
  @keyframes move-frames-71 {
    from {
      transform: translate3d(7vw, 105vh, 0);
    }
    to {
      transform: translate3d(57vw, -118vh, 0);
    }
  }
  .circle-container:nth-child(71) .circle {
    -webkit-animation-delay: 3823ms;
            animation-delay: 3823ms;
  }
  .circle-container:nth-child(72) {
    width: 3px;
    height: 3px;
    -webkit-animation-name: move-frames-72;
            animation-name: move-frames-72;
    -webkit-animation-duration: 35284ms;
            animation-duration: 35284ms;
    -webkit-animation-delay: 9152ms;
            animation-delay: 9152ms;
  }
  @-webkit-keyframes move-frames-72 {
    from {
      transform: translate3d(35vw, 105vh, 0);
    }
    to {
      transform: translate3d(86vw, -131vh, 0);
    }
  }
  @keyframes move-frames-72 {
    from {
      transform: translate3d(35vw, 105vh, 0);
    }
    to {
      transform: translate3d(86vw, -131vh, 0);
    }
  }
  .circle-container:nth-child(72) .circle {
    -webkit-animation-delay: 2482ms;
            animation-delay: 2482ms;
  }
  .circle-container:nth-child(73) {
    width: 4px;
    height: 4px;
    -webkit-animation-name: move-frames-73;
            animation-name: move-frames-73;
    -webkit-animation-duration: 35405ms;
            animation-duration: 35405ms;
    -webkit-animation-delay: 2513ms;
            animation-delay: 2513ms;
  }
  @-webkit-keyframes move-frames-73 {
    from {
      transform: translate3d(79vw, 102vh, 0);
    }
    to {
      transform: translate3d(82vw, -108vh, 0);
    }
  }
  @keyframes move-frames-73 {
    from {
      transform: translate3d(79vw, 102vh, 0);
    }
    to {
      transform: translate3d(82vw, -108vh, 0);
    }
  }
  .circle-container:nth-child(73) .circle {
    -webkit-animation-delay: 3031ms;
            animation-delay: 3031ms;
  }
  .circle-container:nth-child(74) {
    width: 3px;
    height: 3px;
    -webkit-animation-name: move-frames-74;
            animation-name: move-frames-74;
    -webkit-animation-duration: 32499ms;
            animation-duration: 32499ms;
    -webkit-animation-delay: 11951ms;
            animation-delay: 11951ms;
  }
  @-webkit-keyframes move-frames-74 {
    from {
      transform: translate3d(81vw, 101vh, 0);
    }
    to {
      transform: translate3d(7vw, -121vh, 0);
    }
  }
  @keyframes move-frames-74 {
    from {
      transform: translate3d(81vw, 101vh, 0);
    }
    to {
      transform: translate3d(7vw, -121vh, 0);
    }
  }
  .circle-container:nth-child(74) .circle {
    -webkit-animation-delay: 3445ms;
            animation-delay: 3445ms;
  }
  .circle-container:nth-child(75) {
    width: 4px;
    height: 4px;
    -webkit-animation-name: move-frames-75;
            animation-name: move-frames-75;
    -webkit-animation-duration: 33295ms;
            animation-duration: 33295ms;
    -webkit-animation-delay: 3932ms;
            animation-delay: 3932ms;
  }
  @-webkit-keyframes move-frames-75 {
    from {
      transform: translate3d(75vw, 102vh, 0);
    }
    to {
      transform: translate3d(44vw, -120vh, 0);
    }
  }
  @keyframes move-frames-75 {
    from {
      transform: translate3d(75vw, 102vh, 0);
    }
    to {
      transform: translate3d(44vw, -120vh, 0);
    }
  }
  .circle-container:nth-child(75) .circle {
    -webkit-animation-delay: 912ms;
            animation-delay: 912ms;
  }
  .circle-container:nth-child(76) {
    width: 1px;
    height: 1px;
    -webkit-animation-name: move-frames-76;
            animation-name: move-frames-76;
    -webkit-animation-duration: 36762ms;
            animation-duration: 36762ms;
    -webkit-animation-delay: 4090ms;
            animation-delay: 4090ms;
  }
  @-webkit-keyframes move-frames-76 {
    from {
      transform: translate3d(69vw, 102vh, 0);
    }
    to {
      transform: translate3d(79vw, -118vh, 0);
    }
  }
  @keyframes move-frames-76 {
    from {
      transform: translate3d(69vw, 102vh, 0);
    }
    to {
      transform: translate3d(79vw, -118vh, 0);
    }
  }
  .circle-container:nth-child(76) .circle {
    -webkit-animation-delay: 3053ms;
            animation-delay: 3053ms;
  }
  .circle-container:nth-child(77) {
    width: 7px;
    height: 7px;
    -webkit-animation-name: move-frames-77;
            animation-name: move-frames-77;
    -webkit-animation-duration: 29909ms;
            animation-duration: 29909ms;
    -webkit-animation-delay: 21386ms;
            animation-delay: 21386ms;
  }
  @-webkit-keyframes move-frames-77 {
    from {
      transform: translate3d(25vw, 103vh, 0);
    }
    to {
      transform: translate3d(72vw, -109vh, 0);
    }
  }
  @keyframes move-frames-77 {
    from {
      transform: translate3d(25vw, 103vh, 0);
    }
    to {
      transform: translate3d(72vw, -109vh, 0);
    }
  }
  .circle-container:nth-child(77) .circle {
    -webkit-animation-delay: 2356ms;
            animation-delay: 2356ms;
  }
  .circle-container:nth-child(78) {
    width: 2px;
    height: 2px;
    -webkit-animation-name: move-frames-78;
            animation-name: move-frames-78;
    -webkit-animation-duration: 31728ms;
            animation-duration: 31728ms;
    -webkit-animation-delay: 1548ms;
            animation-delay: 1548ms;
  }
  @-webkit-keyframes move-frames-78 {
    from {
      transform: translate3d(79vw, 103vh, 0);
    }
    to {
      transform: translate3d(67vw, -106vh, 0);
    }
  }
  @keyframes move-frames-78 {
    from {
      transform: translate3d(79vw, 103vh, 0);
    }
    to {
      transform: translate3d(67vw, -106vh, 0);
    }
  }
  .circle-container:nth-child(78) .circle {
    -webkit-animation-delay: 2536ms;
            animation-delay: 2536ms;
  }
  .circle-container:nth-child(79) {
    width: 1px;
    height: 1px;
    -webkit-animation-name: move-frames-79;
            animation-name: move-frames-79;
    -webkit-animation-duration: 34172ms;
            animation-duration: 34172ms;
    -webkit-animation-delay: 30959ms;
            animation-delay: 30959ms;
  }
  @-webkit-keyframes move-frames-79 {
    from {
      transform: translate3d(59vw, 108vh, 0);
    }
    to {
      transform: translate3d(26vw, -109vh, 0);
    }
  }
  @keyframes move-frames-79 {
    from {
      transform: translate3d(59vw, 108vh, 0);
    }
    to {
      transform: translate3d(26vw, -109vh, 0);
    }
  }
  .circle-container:nth-child(79) .circle {
    -webkit-animation-delay: 681ms;
            animation-delay: 681ms;
  }
  .circle-container:nth-child(80) {
    width: 1px;
    height: 1px;
    -webkit-animation-name: move-frames-80;
            animation-name: move-frames-80;
    -webkit-animation-duration: 30191ms;
            animation-duration: 30191ms;
    -webkit-animation-delay: 8934ms;
            animation-delay: 8934ms;
  }
  @-webkit-keyframes move-frames-80 {
    from {
      transform: translate3d(3vw, 107vh, 0);
    }
    to {
      transform: translate3d(10vw, -118vh, 0);
    }
  }
  @keyframes move-frames-80 {
    from {
      transform: translate3d(3vw, 107vh, 0);
    }
    to {
      transform: translate3d(10vw, -118vh, 0);
    }
  }
  .circle-container:nth-child(80) .circle {
    -webkit-animation-delay: 2458ms;
            animation-delay: 2458ms;
  }
  .circle-container:nth-child(81) {
    width: 5px;
    height: 5px;
    -webkit-animation-name: move-frames-81;
            animation-name: move-frames-81;
    -webkit-animation-duration: 29762ms;
            animation-duration: 29762ms;
    -webkit-animation-delay: 12603ms;
            animation-delay: 12603ms;
  }
  @-webkit-keyframes move-frames-81 {
    from {
      transform: translate3d(98vw, 105vh, 0);
    }
    to {
      transform: translate3d(24vw, -109vh, 0);
    }
  }
  @keyframes move-frames-81 {
    from {
      transform: translate3d(98vw, 105vh, 0);
    }
    to {
      transform: translate3d(24vw, -109vh, 0);
    }
  }
  .circle-container:nth-child(81) .circle {
    -webkit-animation-delay: 2729ms;
            animation-delay: 2729ms;
  }
  .circle-container:nth-child(82) {
    width: 5px;
    height: 5px;
    -webkit-animation-name: move-frames-82;
            animation-name: move-frames-82;
    -webkit-animation-duration: 36248ms;
            animation-duration: 36248ms;
    -webkit-animation-delay: 28897ms;
            animation-delay: 28897ms;
  }
  @-webkit-keyframes move-frames-82 {
    from {
      transform: translate3d(71vw, 108vh, 0);
    }
    to {
      transform: translate3d(77vw, -125vh, 0);
    }
  }
  @keyframes move-frames-82 {
    from {
      transform: translate3d(71vw, 108vh, 0);
    }
    to {
      transform: translate3d(77vw, -125vh, 0);
    }
  }
  .circle-container:nth-child(82) .circle {
    -webkit-animation-delay: 2978ms;
            animation-delay: 2978ms;
  }
  .circle-container:nth-child(83) {
    width: 6px;
    height: 6px;
    -webkit-animation-name: move-frames-83;
            animation-name: move-frames-83;
    -webkit-animation-duration: 36702ms;
            animation-duration: 36702ms;
    -webkit-animation-delay: 6754ms;
            animation-delay: 6754ms;
  }
  @-webkit-keyframes move-frames-83 {
    from {
      transform: translate3d(50vw, 101vh, 0);
    }
    to {
      transform: translate3d(76vw, -118vh, 0);
    }
  }
  @keyframes move-frames-83 {
    from {
      transform: translate3d(50vw, 101vh, 0);
    }
    to {
      transform: translate3d(76vw, -118vh, 0);
    }
  }
  .circle-container:nth-child(83) .circle {
    -webkit-animation-delay: 3011ms;
            animation-delay: 3011ms;
  }
  .circle-container:nth-child(84) {
    width: 8px;
    height: 8px;
    -webkit-animation-name: move-frames-84;
            animation-name: move-frames-84;
    -webkit-animation-duration: 34696ms;
            animation-duration: 34696ms;
    -webkit-animation-delay: 283ms;
            animation-delay: 283ms;
  }
  @-webkit-keyframes move-frames-84 {
    from {
      transform: translate3d(17vw, 102vh, 0);
    }
    to {
      transform: translate3d(66vw, -116vh, 0);
    }
  }
  @keyframes move-frames-84 {
    from {
      transform: translate3d(17vw, 102vh, 0);
    }
    to {
      transform: translate3d(66vw, -116vh, 0);
    }
  }
  .circle-container:nth-child(84) .circle {
    -webkit-animation-delay: 1721ms;
            animation-delay: 1721ms;
  }
  .circle-container:nth-child(85) {
    width: 5px;
    height: 5px;
    -webkit-animation-name: move-frames-85;
            animation-name: move-frames-85;
    -webkit-animation-duration: 33287ms;
            animation-duration: 33287ms;
    -webkit-animation-delay: 13374ms;
            animation-delay: 13374ms;
  }
  @-webkit-keyframes move-frames-85 {
    from {
      transform: translate3d(94vw, 102vh, 0);
    }
    to {
      transform: translate3d(26vw, -107vh, 0);
    }
  }
  @keyframes move-frames-85 {
    from {
      transform: translate3d(94vw, 102vh, 0);
    }
    to {
      transform: translate3d(26vw, -107vh, 0);
    }
  }
  .circle-container:nth-child(85) .circle {
    -webkit-animation-delay: 2429ms;
            animation-delay: 2429ms;
  }
  .circle-container:nth-child(86) {
    width: 7px;
    height: 7px;
    -webkit-animation-name: move-frames-86;
            animation-name: move-frames-86;
    -webkit-animation-duration: 29201ms;
            animation-duration: 29201ms;
    -webkit-animation-delay: 2103ms;
            animation-delay: 2103ms;
  }
  @-webkit-keyframes move-frames-86 {
    from {
      transform: translate3d(78vw, 104vh, 0);
    }
    to {
      transform: translate3d(14vw, -125vh, 0);
    }
  }
  @keyframes move-frames-86 {
    from {
      transform: translate3d(78vw, 104vh, 0);
    }
    to {
      transform: translate3d(14vw, -125vh, 0);
    }
  }
  .circle-container:nth-child(86) .circle {
    -webkit-animation-delay: 188ms;
            animation-delay: 188ms;
  }
  .circle-container:nth-child(87) {
    width: 8px;
    height: 8px;
    -webkit-animation-name: move-frames-87;
            animation-name: move-frames-87;
    -webkit-animation-duration: 36391ms;
            animation-duration: 36391ms;
    -webkit-animation-delay: 31734ms;
            animation-delay: 31734ms;
  }
  @-webkit-keyframes move-frames-87 {
    from {
      transform: translate3d(69vw, 108vh, 0);
    }
    to {
      transform: translate3d(94vw, -121vh, 0);
    }
  }
  @keyframes move-frames-87 {
    from {
      transform: translate3d(69vw, 108vh, 0);
    }
    to {
      transform: translate3d(94vw, -121vh, 0);
    }
  }
  .circle-container:nth-child(87) .circle {
    -webkit-animation-delay: 1769ms;
            animation-delay: 1769ms;
  }
  .circle-container:nth-child(88) {
    width: 2px;
    height: 2px;
    -webkit-animation-name: move-frames-88;
            animation-name: move-frames-88;
    -webkit-animation-duration: 31641ms;
            animation-duration: 31641ms;
    -webkit-animation-delay: 12830ms;
            animation-delay: 12830ms;
  }
  @-webkit-keyframes move-frames-88 {
    from {
      transform: translate3d(26vw, 108vh, 0);
    }
    to {
      transform: translate3d(14vw, -133vh, 0);
    }
  }
  @keyframes move-frames-88 {
    from {
      transform: translate3d(26vw, 108vh, 0);
    }
    to {
      transform: translate3d(14vw, -133vh, 0);
    }
  }
  .circle-container:nth-child(88) .circle {
    -webkit-animation-delay: 1336ms;
            animation-delay: 1336ms;
  }
  .circle-container:nth-child(89) {
    width: 6px;
    height: 6px;
    -webkit-animation-name: move-frames-89;
            animation-name: move-frames-89;
    -webkit-animation-duration: 30128ms;
            animation-duration: 30128ms;
    -webkit-animation-delay: 14256ms;
            animation-delay: 14256ms;
  }
  @-webkit-keyframes move-frames-89 {
    from {
      transform: translate3d(100vw, 105vh, 0);
    }
    to {
      transform: translate3d(52vw, -118vh, 0);
    }
  }
  @keyframes move-frames-89 {
    from {
      transform: translate3d(100vw, 105vh, 0);
    }
    to {
      transform: translate3d(52vw, -118vh, 0);
    }
  }
  .circle-container:nth-child(89) .circle {
    -webkit-animation-delay: 3610ms;
            animation-delay: 3610ms;
  }
  .circle-container:nth-child(90) {
    width: 5px;
    height: 5px;
    -webkit-animation-name: move-frames-90;
            animation-name: move-frames-90;
    -webkit-animation-duration: 31739ms;
            animation-duration: 31739ms;
    -webkit-animation-delay: 5534ms;
            animation-delay: 5534ms;
  }
  @-webkit-keyframes move-frames-90 {
    from {
      transform: translate3d(68vw, 102vh, 0);
    }
    to {
      transform: translate3d(30vw, -120vh, 0);
    }
  }
  @keyframes move-frames-90 {
    from {
      transform: translate3d(68vw, 102vh, 0);
    }
    to {
      transform: translate3d(30vw, -120vh, 0);
    }
  }
  .circle-container:nth-child(90) .circle {
    -webkit-animation-delay: 3088ms;
            animation-delay: 3088ms;
  }
  .circle-container:nth-child(91) {
    width: 4px;
    height: 4px;
    -webkit-animation-name: move-frames-91;
            animation-name: move-frames-91;
    -webkit-animation-duration: 31572ms;
            animation-duration: 31572ms;
    -webkit-animation-delay: 23601ms;
            animation-delay: 23601ms;
  }
  @-webkit-keyframes move-frames-91 {
    from {
      transform: translate3d(100vw, 109vh, 0);
    }
    to {
      transform: translate3d(34vw, -129vh, 0);
    }
  }
  @keyframes move-frames-91 {
    from {
      transform: translate3d(100vw, 109vh, 0);
    }
    to {
      transform: translate3d(34vw, -129vh, 0);
    }
  }
  .circle-container:nth-child(91) .circle {
    -webkit-animation-delay: 2868ms;
            animation-delay: 2868ms;
  }
  .circle-container:nth-child(92) {
    width: 5px;
    height: 5px;
    -webkit-animation-name: move-frames-92;
            animation-name: move-frames-92;
    -webkit-animation-duration: 29758ms;
            animation-duration: 29758ms;
    -webkit-animation-delay: 25430ms;
            animation-delay: 25430ms;
  }
  @-webkit-keyframes move-frames-92 {
    from {
      transform: translate3d(21vw, 106vh, 0);
    }
    to {
      transform: translate3d(48vw, -132vh, 0);
    }
  }
  @keyframes move-frames-92 {
    from {
      transform: translate3d(21vw, 106vh, 0);
    }
    to {
      transform: translate3d(48vw, -132vh, 0);
    }
  }
  .circle-container:nth-child(92) .circle {
    -webkit-animation-delay: 802ms;
            animation-delay: 802ms;
  }
  .circle-container:nth-child(93) {
    width: 4px;
    height: 4px;
    -webkit-animation-name: move-frames-93;
            animation-name: move-frames-93;
    -webkit-animation-duration: 31432ms;
            animation-duration: 31432ms;
    -webkit-animation-delay: 21382ms;
            animation-delay: 21382ms;
  }
  @-webkit-keyframes move-frames-93 {
    from {
      transform: translate3d(26vw, 105vh, 0);
    }
    to {
      transform: translate3d(50vw, -120vh, 0);
    }
  }
  @keyframes move-frames-93 {
    from {
      transform: translate3d(26vw, 105vh, 0);
    }
    to {
      transform: translate3d(50vw, -120vh, 0);
    }
  }
  .circle-container:nth-child(93) .circle {
    -webkit-animation-delay: 3415ms;
            animation-delay: 3415ms;
  }
  .circle-container:nth-child(94) {
    width: 6px;
    height: 6px;
    -webkit-animation-name: move-frames-94;
            animation-name: move-frames-94;
    -webkit-animation-duration: 36018ms;
            animation-duration: 36018ms;
    -webkit-animation-delay: 18540ms;
            animation-delay: 18540ms;
  }
  @-webkit-keyframes move-frames-94 {
    from {
      transform: translate3d(21vw, 106vh, 0);
    }
    to {
      transform: translate3d(100vw, -119vh, 0);
    }
  }
  @keyframes move-frames-94 {
    from {
      transform: translate3d(21vw, 106vh, 0);
    }
    to {
      transform: translate3d(100vw, -119vh, 0);
    }
  }
  .circle-container:nth-child(94) .circle {
    -webkit-animation-delay: 2095ms;
            animation-delay: 2095ms;
  }
  .circle-container:nth-child(95) {
    width: 6px;
    height: 6px;
    -webkit-animation-name: move-frames-95;
            animation-name: move-frames-95;
    -webkit-animation-duration: 30835ms;
            animation-duration: 30835ms;
    -webkit-animation-delay: 21110ms;
            animation-delay: 21110ms;
  }
  @-webkit-keyframes move-frames-95 {
    from {
      transform: translate3d(1vw, 109vh, 0);
    }
    to {
      transform: translate3d(48vw, -129vh, 0);
    }
  }
  @keyframes move-frames-95 {
    from {
      transform: translate3d(1vw, 109vh, 0);
    }
    to {
      transform: translate3d(48vw, -129vh, 0);
    }
  }
  .circle-container:nth-child(95) .circle {
    -webkit-animation-delay: 3577ms;
            animation-delay: 3577ms;
  }
  .circle-container:nth-child(96) {
    width: 4px;
    height: 4px;
    -webkit-animation-name: move-frames-96;
            animation-name: move-frames-96;
    -webkit-animation-duration: 34830ms;
            animation-duration: 34830ms;
    -webkit-animation-delay: 32888ms;
            animation-delay: 32888ms;
  }
  @-webkit-keyframes move-frames-96 {
    from {
      transform: translate3d(96vw, 106vh, 0);
    }
    to {
      transform: translate3d(3vw, -136vh, 0);
    }
  }
  @keyframes move-frames-96 {
    from {
      transform: translate3d(96vw, 106vh, 0);
    }
    to {
      transform: translate3d(3vw, -136vh, 0);
    }
  }
  .circle-container:nth-child(96) .circle {
    -webkit-animation-delay: 3493ms;
            animation-delay: 3493ms;
  }
  .circle-container:nth-child(97) {
    width: 7px;
    height: 7px;
    -webkit-animation-name: move-frames-97;
            animation-name: move-frames-97;
    -webkit-animation-duration: 34639ms;
            animation-duration: 34639ms;
    -webkit-animation-delay: 2876ms;
            animation-delay: 2876ms;
  }
  @-webkit-keyframes move-frames-97 {
    from {
      transform: translate3d(55vw, 103vh, 0);
    }
    to {
      transform: translate3d(45vw, -112vh, 0);
    }
  }
  @keyframes move-frames-97 {
    from {
      transform: translate3d(55vw, 103vh, 0);
    }
    to {
      transform: translate3d(45vw, -112vh, 0);
    }
  }
  .circle-container:nth-child(97) .circle {
    -webkit-animation-delay: 2176ms;
            animation-delay: 2176ms;
  }
  .circle-container:nth-child(98) {
    width: 1px;
    height: 1px;
    -webkit-animation-name: move-frames-98;
            animation-name: move-frames-98;
    -webkit-animation-duration: 35331ms;
            animation-duration: 35331ms;
    -webkit-animation-delay: 23189ms;
            animation-delay: 23189ms;
  }
  @-webkit-keyframes move-frames-98 {
    from {
      transform: translate3d(35vw, 109vh, 0);
    }
    to {
      transform: translate3d(56vw, -134vh, 0);
    }
  }
  @keyframes move-frames-98 {
    from {
      transform: translate3d(35vw, 109vh, 0);
    }
    to {
      transform: translate3d(56vw, -134vh, 0);
    }
  }
  .circle-container:nth-child(98) .circle {
    -webkit-animation-delay: 1928ms;
            animation-delay: 1928ms;
  }
  .circle-container:nth-child(99) {
    width: 7px;
    height: 7px;
    -webkit-animation-name: move-frames-99;
            animation-name: move-frames-99;
    -webkit-animation-duration: 32488ms;
            animation-duration: 32488ms;
    -webkit-animation-delay: 32929ms;
            animation-delay: 32929ms;
  }
  @-webkit-keyframes move-frames-99 {
    from {
      transform: translate3d(49vw, 110vh, 0);
    }
    to {
      transform: translate3d(13vw, -121vh, 0);
    }
  }
  @keyframes move-frames-99 {
    from {
      transform: translate3d(49vw, 110vh, 0);
    }
    to {
      transform: translate3d(13vw, -121vh, 0);
    }
  }
  .circle-container:nth-child(99) .circle {
    -webkit-animation-delay: 2427ms;
            animation-delay: 2427ms;
  }
  .circle-container:nth-child(100) {
    width: 3px;
    height: 3px;
    -webkit-animation-name: move-frames-100;
            animation-name: move-frames-100;
    -webkit-animation-duration: 29098ms;
            animation-duration: 29098ms;
    -webkit-animation-delay: 31223ms;
            animation-delay: 31223ms;
  }
  @-webkit-keyframes move-frames-100 {
    from {
      transform: translate3d(25vw, 110vh, 0);
    }
    to {
      transform: translate3d(57vw, -133vh, 0);
    }
  }
  @keyframes move-frames-100 {
    from {
      transform: translate3d(25vw, 110vh, 0);
    }
    to {
      transform: translate3d(57vw, -133vh, 0);
    }
  }
  .circle-container:nth-child(100) .circle {
    -webkit-animation-delay: 1610ms;
            animation-delay: 1610ms;
  }
  .circle-container:nth-child(101) {
    width: 3px;
    height: 3px;
    -webkit-animation-name: move-frames-101;
            animation-name: move-frames-101;
    -webkit-animation-duration: 36431ms;
            animation-duration: 36431ms;
    -webkit-animation-delay: 35867ms;
            animation-delay: 35867ms;
  }
  @-webkit-keyframes move-frames-101 {
    from {
      transform: translate3d(59vw, 109vh, 0);
    }
    to {
      transform: translate3d(22vw, -113vh, 0);
    }
  }
  @keyframes move-frames-101 {
    from {
      transform: translate3d(59vw, 109vh, 0);
    }
    to {
      transform: translate3d(22vw, -113vh, 0);
    }
  }
  .circle-container:nth-child(101) .circle {
    -webkit-animation-delay: 3829ms;
            animation-delay: 3829ms;
  }
  .circle-container:nth-child(102) {
    width: 6px;
    height: 6px;
    -webkit-animation-name: move-frames-102;
            animation-name: move-frames-102;
    -webkit-animation-duration: 31891ms;
            animation-duration: 31891ms;
    -webkit-animation-delay: 10547ms;
            animation-delay: 10547ms;
  }
  @-webkit-keyframes move-frames-102 {
    from {
      transform: translate3d(16vw, 107vh, 0);
    }
    to {
      transform: translate3d(76vw, -131vh, 0);
    }
  }
  @keyframes move-frames-102 {
    from {
      transform: translate3d(16vw, 107vh, 0);
    }
    to {
      transform: translate3d(76vw, -131vh, 0);
    }
  }
  .circle-container:nth-child(102) .circle {
    -webkit-animation-delay: 2852ms;
            animation-delay: 2852ms;
  }
  .circle-container:nth-child(103) {
    width: 8px;
    height: 8px;
    -webkit-animation-name: move-frames-103;
            animation-name: move-frames-103;
    -webkit-animation-duration: 36290ms;
            animation-duration: 36290ms;
    -webkit-animation-delay: 34170ms;
            animation-delay: 34170ms;
  }
  @-webkit-keyframes move-frames-103 {
    from {
      transform: translate3d(37vw, 109vh, 0);
    }
    to {
      transform: translate3d(99vw, -121vh, 0);
    }
  }
  @keyframes move-frames-103 {
    from {
      transform: translate3d(37vw, 109vh, 0);
    }
    to {
      transform: translate3d(99vw, -121vh, 0);
    }
  }
  .circle-container:nth-child(103) .circle {
    -webkit-animation-delay: 2835ms;
            animation-delay: 2835ms;
  }
  .circle-container:nth-child(104) {
    width: 7px;
    height: 7px;
    -webkit-animation-name: move-frames-104;
            animation-name: move-frames-104;
    -webkit-animation-duration: 31619ms;
            animation-duration: 31619ms;
    -webkit-animation-delay: 19237ms;
            animation-delay: 19237ms;
  }
  @-webkit-keyframes move-frames-104 {
    from {
      transform: translate3d(52vw, 109vh, 0);
    }
    to {
      transform: translate3d(2vw, -128vh, 0);
    }
  }
  @keyframes move-frames-104 {
    from {
      transform: translate3d(52vw, 109vh, 0);
    }
    to {
      transform: translate3d(2vw, -128vh, 0);
    }
  }
  .circle-container:nth-child(104) .circle {
    -webkit-animation-delay: 101ms;
            animation-delay: 101ms;
  }
  .circle-container:nth-child(105) {
    width: 1px;
    height: 1px;
    -webkit-animation-name: move-frames-105;
            animation-name: move-frames-105;
    -webkit-animation-duration: 34001ms;
            animation-duration: 34001ms;
    -webkit-animation-delay: 32385ms;
            animation-delay: 32385ms;
  }
  @-webkit-keyframes move-frames-105 {
    from {
      transform: translate3d(90vw, 103vh, 0);
    }
    to {
      transform: translate3d(22vw, -121vh, 0);
    }
  }
  @keyframes move-frames-105 {
    from {
      transform: translate3d(90vw, 103vh, 0);
    }
    to {
      transform: translate3d(22vw, -121vh, 0);
    }
  }
  .circle-container:nth-child(105) .circle {
    -webkit-animation-delay: 3379ms;
            animation-delay: 3379ms;
  }
  .circle-container:nth-child(106) {
    width: 3px;
    height: 3px;
    -webkit-animation-name: move-frames-106;
            animation-name: move-frames-106;
    -webkit-animation-duration: 36977ms;
            animation-duration: 36977ms;
    -webkit-animation-delay: 17934ms;
            animation-delay: 17934ms;
  }
  @-webkit-keyframes move-frames-106 {
    from {
      transform: translate3d(54vw, 104vh, 0);
    }
    to {
      transform: translate3d(94vw, -113vh, 0);
    }
  }
  @keyframes move-frames-106 {
    from {
      transform: translate3d(54vw, 104vh, 0);
    }
    to {
      transform: translate3d(94vw, -113vh, 0);
    }
  }
  .circle-container:nth-child(106) .circle {
    -webkit-animation-delay: 1893ms;
            animation-delay: 1893ms;
  }
  .circle-container:nth-child(107) {
    width: 5px;
    height: 5px;
    -webkit-animation-name: move-frames-107;
            animation-name: move-frames-107;
    -webkit-animation-duration: 29664ms;
            animation-duration: 29664ms;
    -webkit-animation-delay: 32378ms;
            animation-delay: 32378ms;
  }
  @-webkit-keyframes move-frames-107 {
    from {
      transform: translate3d(88vw, 107vh, 0);
    }
    to {
      transform: translate3d(49vw, -127vh, 0);
    }
  }
  @keyframes move-frames-107 {
    from {
      transform: translate3d(88vw, 107vh, 0);
    }
    to {
      transform: translate3d(49vw, -127vh, 0);
    }
  }
  .circle-container:nth-child(107) .circle {
    -webkit-animation-delay: 3162ms;
            animation-delay: 3162ms;
  }
  .circle-container:nth-child(108) {
    width: 2px;
    height: 2px;
    -webkit-animation-name: move-frames-108;
            animation-name: move-frames-108;
    -webkit-animation-duration: 32119ms;
            animation-duration: 32119ms;
    -webkit-animation-delay: 6306ms;
            animation-delay: 6306ms;
  }
  @-webkit-keyframes move-frames-108 {
    from {
      transform: translate3d(22vw, 106vh, 0);
    }
    to {
      transform: translate3d(32vw, -124vh, 0);
    }
  }
  @keyframes move-frames-108 {
    from {
      transform: translate3d(22vw, 106vh, 0);
    }
    to {
      transform: translate3d(32vw, -124vh, 0);
    }
  }
  .circle-container:nth-child(108) .circle {
    -webkit-animation-delay: 658ms;
            animation-delay: 658ms;
  }
  .circle-container:nth-child(109) {
    width: 1px;
    height: 1px;
    -webkit-animation-name: move-frames-109;
            animation-name: move-frames-109;
    -webkit-animation-duration: 30335ms;
            animation-duration: 30335ms;
    -webkit-animation-delay: 36958ms;
            animation-delay: 36958ms;
  }
  @-webkit-keyframes move-frames-109 {
    from {
      transform: translate3d(56vw, 102vh, 0);
    }
    to {
      transform: translate3d(53vw, -107vh, 0);
    }
  }
  @keyframes move-frames-109 {
    from {
      transform: translate3d(56vw, 102vh, 0);
    }
    to {
      transform: translate3d(53vw, -107vh, 0);
    }
  }
  .circle-container:nth-child(109) .circle {
    -webkit-animation-delay: 2574ms;
            animation-delay: 2574ms;
  }
  .circle-container:nth-child(110) {
    width: 3px;
    height: 3px;
    -webkit-animation-name: move-frames-110;
            animation-name: move-frames-110;
    -webkit-animation-duration: 34556ms;
            animation-duration: 34556ms;
    -webkit-animation-delay: 31393ms;
            animation-delay: 31393ms;
  }
  @-webkit-keyframes move-frames-110 {
    from {
      transform: translate3d(35vw, 101vh, 0);
    }
    to {
      transform: translate3d(46vw, -105vh, 0);
    }
  }
  @keyframes move-frames-110 {
    from {
      transform: translate3d(35vw, 101vh, 0);
    }
    to {
      transform: translate3d(46vw, -105vh, 0);
    }
  }
  .circle-container:nth-child(110) .circle {
    -webkit-animation-delay: 3824ms;
            animation-delay: 3824ms;
  }
  .circle-container:nth-child(111) {
    width: 4px;
    height: 4px;
    -webkit-animation-name: move-frames-111;
            animation-name: move-frames-111;
    -webkit-animation-duration: 32225ms;
            animation-duration: 32225ms;
    -webkit-animation-delay: 22908ms;
            animation-delay: 22908ms;
  }
  @-webkit-keyframes move-frames-111 {
    from {
      transform: translate3d(62vw, 109vh, 0);
    }
    to {
      transform: translate3d(79vw, -131vh, 0);
    }
  }
  @keyframes move-frames-111 {
    from {
      transform: translate3d(62vw, 109vh, 0);
    }
    to {
      transform: translate3d(79vw, -131vh, 0);
    }
  }
  .circle-container:nth-child(111) .circle {
    -webkit-animation-delay: 2808ms;
            animation-delay: 2808ms;
  }
  .circle-container:nth-child(112) {
    width: 5px;
    height: 5px;
    -webkit-animation-name: move-frames-112;
            animation-name: move-frames-112;
    -webkit-animation-duration: 36040ms;
            animation-duration: 36040ms;
    -webkit-animation-delay: 36779ms;
            animation-delay: 36779ms;
  }
  @-webkit-keyframes move-frames-112 {
    from {
      transform: translate3d(45vw, 101vh, 0);
    }
    to {
      transform: translate3d(11vw, -106vh, 0);
    }
  }
  @keyframes move-frames-112 {
    from {
      transform: translate3d(45vw, 101vh, 0);
    }
    to {
      transform: translate3d(11vw, -106vh, 0);
    }
  }
  .circle-container:nth-child(112) .circle {
    -webkit-animation-delay: 3436ms;
            animation-delay: 3436ms;
  }
  .circle-container:nth-child(113) {
    width: 8px;
    height: 8px;
    -webkit-animation-name: move-frames-113;
            animation-name: move-frames-113;
    -webkit-animation-duration: 31233ms;
            animation-duration: 31233ms;
    -webkit-animation-delay: 3113ms;
            animation-delay: 3113ms;
  }
  @-webkit-keyframes move-frames-113 {
    from {
      transform: translate3d(83vw, 103vh, 0);
    }
    to {
      transform: translate3d(3vw, -127vh, 0);
    }
  }
  @keyframes move-frames-113 {
    from {
      transform: translate3d(83vw, 103vh, 0);
    }
    to {
      transform: translate3d(3vw, -127vh, 0);
    }
  }
  .circle-container:nth-child(113) .circle {
    -webkit-animation-delay: 2398ms;
            animation-delay: 2398ms;
  }
  .circle-container:nth-child(114) {
    width: 3px;
    height: 3px;
    -webkit-animation-name: move-frames-114;
            animation-name: move-frames-114;
    -webkit-animation-duration: 32882ms;
            animation-duration: 32882ms;
    -webkit-animation-delay: 19326ms;
            animation-delay: 19326ms;
  }
  @-webkit-keyframes move-frames-114 {
    from {
      transform: translate3d(3vw, 104vh, 0);
    }
    to {
      transform: translate3d(3vw, -124vh, 0);
    }
  }
  @keyframes move-frames-114 {
    from {
      transform: translate3d(3vw, 104vh, 0);
    }
    to {
      transform: translate3d(3vw, -124vh, 0);
    }
  }
  .circle-container:nth-child(114) .circle {
    -webkit-animation-delay: 2976ms;
            animation-delay: 2976ms;
  }
  .circle-container:nth-child(115) {
    width: 5px;
    height: 5px;
    -webkit-animation-name: move-frames-115;
            animation-name: move-frames-115;
    -webkit-animation-duration: 30357ms;
            animation-duration: 30357ms;
    -webkit-animation-delay: 8802ms;
            animation-delay: 8802ms;
  }
  @-webkit-keyframes move-frames-115 {
    from {
      transform: translate3d(61vw, 108vh, 0);
    }
    to {
      transform: translate3d(61vw, -118vh, 0);
    }
  }
  @keyframes move-frames-115 {
    from {
      transform: translate3d(61vw, 108vh, 0);
    }
    to {
      transform: translate3d(61vw, -118vh, 0);
    }
  }
  .circle-container:nth-child(115) .circle {
    -webkit-animation-delay: 847ms;
            animation-delay: 847ms;
  }
  .circle-container:nth-child(116) {
    width: 4px;
    height: 4px;
    -webkit-animation-name: move-frames-116;
            animation-name: move-frames-116;
    -webkit-animation-duration: 36410ms;
            animation-duration: 36410ms;
    -webkit-animation-delay: 25828ms;
            animation-delay: 25828ms;
  }
  @-webkit-keyframes move-frames-116 {
    from {
      transform: translate3d(64vw, 105vh, 0);
    }
    to {
      transform: translate3d(31vw, -128vh, 0);
    }
  }
  @keyframes move-frames-116 {
    from {
      transform: translate3d(64vw, 105vh, 0);
    }
    to {
      transform: translate3d(31vw, -128vh, 0);
    }
  }
  .circle-container:nth-child(116) .circle {
    -webkit-animation-delay: 842ms;
            animation-delay: 842ms;
  }
  .circle-container:nth-child(117) {
    width: 4px;
    height: 4px;
    -webkit-animation-name: move-frames-117;
            animation-name: move-frames-117;
    -webkit-animation-duration: 33822ms;
            animation-duration: 33822ms;
    -webkit-animation-delay: 8112ms;
            animation-delay: 8112ms;
  }
  @-webkit-keyframes move-frames-117 {
    from {
      transform: translate3d(48vw, 101vh, 0);
    }
    to {
      transform: translate3d(84vw, -123vh, 0);
    }
  }
  @keyframes move-frames-117 {
    from {
      transform: translate3d(48vw, 101vh, 0);
    }
    to {
      transform: translate3d(84vw, -123vh, 0);
    }
  }
  .circle-container:nth-child(117) .circle {
    -webkit-animation-delay: 3804ms;
            animation-delay: 3804ms;
  }
  .circle-container:nth-child(118) {
    width: 4px;
    height: 4px;
    -webkit-animation-name: move-frames-118;
            animation-name: move-frames-118;
    -webkit-animation-duration: 33609ms;
            animation-duration: 33609ms;
    -webkit-animation-delay: 28091ms;
            animation-delay: 28091ms;
  }
  @-webkit-keyframes move-frames-118 {
    from {
      transform: translate3d(12vw, 106vh, 0);
    }
    to {
      transform: translate3d(82vw, -124vh, 0);
    }
  }
  @keyframes move-frames-118 {
    from {
      transform: translate3d(12vw, 106vh, 0);
    }
    to {
      transform: translate3d(82vw, -124vh, 0);
    }
  }
  .circle-container:nth-child(118) .circle {
    -webkit-animation-delay: 1809ms;
            animation-delay: 1809ms;
  }
  .circle-container:nth-child(119) {
    width: 7px;
    height: 7px;
    -webkit-animation-name: move-frames-119;
            animation-name: move-frames-119;
    -webkit-animation-duration: 36605ms;
            animation-duration: 36605ms;
    -webkit-animation-delay: 5937ms;
            animation-delay: 5937ms;
  }
  @-webkit-keyframes move-frames-119 {
    from {
      transform: translate3d(98vw, 106vh, 0);
    }
    to {
      transform: translate3d(89vw, -107vh, 0);
    }
  }
  @keyframes move-frames-119 {
    from {
      transform: translate3d(98vw, 106vh, 0);
    }
    to {
      transform: translate3d(89vw, -107vh, 0);
    }
  }
  .circle-container:nth-child(119) .circle {
    -webkit-animation-delay: 391ms;
            animation-delay: 391ms;
  }
  .circle-container:nth-child(120) {
    width: 7px;
    height: 7px;
    -webkit-animation-name: move-frames-120;
            animation-name: move-frames-120;
    -webkit-animation-duration: 32210ms;
            animation-duration: 32210ms;
    -webkit-animation-delay: 3500ms;
            animation-delay: 3500ms;
  }
  @-webkit-keyframes move-frames-120 {
    from {
      transform: translate3d(76vw, 101vh, 0);
    }
    to {
      transform: translate3d(22vw, -109vh, 0);
    }
  }
  @keyframes move-frames-120 {
    from {
      transform: translate3d(76vw, 101vh, 0);
    }
    to {
      transform: translate3d(22vw, -109vh, 0);
    }
  }
  .circle-container:nth-child(120) .circle {
    -webkit-animation-delay: 1917ms;
            animation-delay: 1917ms;
  }
  .circle-container:nth-child(121) {
    width: 8px;
    height: 8px;
    -webkit-animation-name: move-frames-121;
            animation-name: move-frames-121;
    -webkit-animation-duration: 31791ms;
            animation-duration: 31791ms;
    -webkit-animation-delay: 4884ms;
            animation-delay: 4884ms;
  }
  @-webkit-keyframes move-frames-121 {
    from {
      transform: translate3d(60vw, 109vh, 0);
    }
    to {
      transform: translate3d(88vw, -110vh, 0);
    }
  }
  @keyframes move-frames-121 {
    from {
      transform: translate3d(60vw, 109vh, 0);
    }
    to {
      transform: translate3d(88vw, -110vh, 0);
    }
  }
  .circle-container:nth-child(121) .circle {
    -webkit-animation-delay: 231ms;
            animation-delay: 231ms;
  }
  .circle-container:nth-child(122) {
    width: 7px;
    height: 7px;
    -webkit-animation-name: move-frames-122;
            animation-name: move-frames-122;
    -webkit-animation-duration: 33972ms;
            animation-duration: 33972ms;
    -webkit-animation-delay: 19307ms;
            animation-delay: 19307ms;
  }
  @-webkit-keyframes move-frames-122 {
    from {
      transform: translate3d(99vw, 109vh, 0);
    }
    to {
      transform: translate3d(80vw, -139vh, 0);
    }
  }
  @keyframes move-frames-122 {
    from {
      transform: translate3d(99vw, 109vh, 0);
    }
    to {
      transform: translate3d(80vw, -139vh, 0);
    }
  }
  .circle-container:nth-child(122) .circle {
    -webkit-animation-delay: 2681ms;
            animation-delay: 2681ms;
  }
  .circle-container:nth-child(123) {
    width: 8px;
    height: 8px;
    -webkit-animation-name: move-frames-123;
            animation-name: move-frames-123;
    -webkit-animation-duration: 30696ms;
            animation-duration: 30696ms;
    -webkit-animation-delay: 29139ms;
            animation-delay: 29139ms;
  }
  @-webkit-keyframes move-frames-123 {
    from {
      transform: translate3d(85vw, 102vh, 0);
    }
    to {
      transform: translate3d(15vw, -109vh, 0);
    }
  }
  @keyframes move-frames-123 {
    from {
      transform: translate3d(85vw, 102vh, 0);
    }
    to {
      transform: translate3d(15vw, -109vh, 0);
    }
  }
  .circle-container:nth-child(123) .circle {
    -webkit-animation-delay: 2466ms;
            animation-delay: 2466ms;
  }
  .circle-container:nth-child(124) {
    width: 4px;
    height: 4px;
    -webkit-animation-name: move-frames-124;
            animation-name: move-frames-124;
    -webkit-animation-duration: 34695ms;
            animation-duration: 34695ms;
    -webkit-animation-delay: 940ms;
            animation-delay: 940ms;
  }
  @-webkit-keyframes move-frames-124 {
    from {
      transform: translate3d(42vw, 110vh, 0);
    }
    to {
      transform: translate3d(84vw, -133vh, 0);
    }
  }
  @keyframes move-frames-124 {
    from {
      transform: translate3d(42vw, 110vh, 0);
    }
    to {
      transform: translate3d(84vw, -133vh, 0);
    }
  }
  .circle-container:nth-child(124) .circle {
    -webkit-animation-delay: 2304ms;
            animation-delay: 2304ms;
  }
  .circle-container:nth-child(125) {
    width: 3px;
    height: 3px;
    -webkit-animation-name: move-frames-125;
            animation-name: move-frames-125;
    -webkit-animation-duration: 31239ms;
            animation-duration: 31239ms;
    -webkit-animation-delay: 27630ms;
            animation-delay: 27630ms;
  }
  @-webkit-keyframes move-frames-125 {
    from {
      transform: translate3d(31vw, 106vh, 0);
    }
    to {
      transform: translate3d(56vw, -124vh, 0);
    }
  }
  @keyframes move-frames-125 {
    from {
      transform: translate3d(31vw, 106vh, 0);
    }
    to {
      transform: translate3d(56vw, -124vh, 0);
    }
  }
  .circle-container:nth-child(125) .circle {
    -webkit-animation-delay: 2371ms;
            animation-delay: 2371ms;
  }
  .circle-container:nth-child(126) {
    width: 6px;
    height: 6px;
    -webkit-animation-name: move-frames-126;
            animation-name: move-frames-126;
    -webkit-animation-duration: 28269ms;
            animation-duration: 28269ms;
    -webkit-animation-delay: 10366ms;
            animation-delay: 10366ms;
  }
  @-webkit-keyframes move-frames-126 {
    from {
      transform: translate3d(80vw, 101vh, 0);
    }
    to {
      transform: translate3d(81vw, -131vh, 0);
    }
  }
  @keyframes move-frames-126 {
    from {
      transform: translate3d(80vw, 101vh, 0);
    }
    to {
      transform: translate3d(81vw, -131vh, 0);
    }
  }
  .circle-container:nth-child(126) .circle {
    -webkit-animation-delay: 1115ms;
            animation-delay: 1115ms;
  }
  .circle-container:nth-child(127) {
    width: 3px;
    height: 3px;
    -webkit-animation-name: move-frames-127;
            animation-name: move-frames-127;
    -webkit-animation-duration: 30947ms;
            animation-duration: 30947ms;
    -webkit-animation-delay: 14017ms;
            animation-delay: 14017ms;
  }
  @-webkit-keyframes move-frames-127 {
    from {
      transform: translate3d(18vw, 104vh, 0);
    }
    to {
      transform: translate3d(90vw, -115vh, 0);
    }
  }
  @keyframes move-frames-127 {
    from {
      transform: translate3d(18vw, 104vh, 0);
    }
    to {
      transform: translate3d(90vw, -115vh, 0);
    }
  }
  .circle-container:nth-child(127) .circle {
    -webkit-animation-delay: 2839ms;
            animation-delay: 2839ms;
  }
  .circle-container:nth-child(128) {
    width: 3px;
    height: 3px;
    -webkit-animation-name: move-frames-128;
            animation-name: move-frames-128;
    -webkit-animation-duration: 32094ms;
            animation-duration: 32094ms;
    -webkit-animation-delay: 31702ms;
            animation-delay: 31702ms;
  }
  @-webkit-keyframes move-frames-128 {
    from {
      transform: translate3d(52vw, 102vh, 0);
    }
    to {
      transform: translate3d(54vw, -107vh, 0);
    }
  }
  @keyframes move-frames-128 {
    from {
      transform: translate3d(52vw, 102vh, 0);
    }
    to {
      transform: translate3d(54vw, -107vh, 0);
    }
  }
  .circle-container:nth-child(128) .circle {
    -webkit-animation-delay: 3153ms;
            animation-delay: 3153ms;
  }
  .circle-container:nth-child(129) {
    width: 2px;
    height: 2px;
    -webkit-animation-name: move-frames-129;
            animation-name: move-frames-129;
    -webkit-animation-duration: 33503ms;
            animation-duration: 33503ms;
    -webkit-animation-delay: 1979ms;
            animation-delay: 1979ms;
  }
  @-webkit-keyframes move-frames-129 {
    from {
      transform: translate3d(72vw, 106vh, 0);
    }
    to {
      transform: translate3d(36vw, -118vh, 0);
    }
  }
  @keyframes move-frames-129 {
    from {
      transform: translate3d(72vw, 106vh, 0);
    }
    to {
      transform: translate3d(36vw, -118vh, 0);
    }
  }
  .circle-container:nth-child(129) .circle {
    -webkit-animation-delay: 532ms;
            animation-delay: 532ms;
  }
  .circle-container:nth-child(130) {
    width: 6px;
    height: 6px;
    -webkit-animation-name: move-frames-130;
            animation-name: move-frames-130;
    -webkit-animation-duration: 33465ms;
            animation-duration: 33465ms;
    -webkit-animation-delay: 11946ms;
            animation-delay: 11946ms;
  }
  @-webkit-keyframes move-frames-130 {
    from {
      transform: translate3d(27vw, 102vh, 0);
    }
    to {
      transform: translate3d(13vw, -114vh, 0);
    }
  }
  @keyframes move-frames-130 {
    from {
      transform: translate3d(27vw, 102vh, 0);
    }
    to {
      transform: translate3d(13vw, -114vh, 0);
    }
  }
  .circle-container:nth-child(130) .circle {
    -webkit-animation-delay: 1799ms;
            animation-delay: 1799ms;
  }
  .circle-container:nth-child(131) {
    width: 6px;
    height: 6px;
    -webkit-animation-name: move-frames-131;
            animation-name: move-frames-131;
    -webkit-animation-duration: 36068ms;
            animation-duration: 36068ms;
    -webkit-animation-delay: 8255ms;
            animation-delay: 8255ms;
  }
  @-webkit-keyframes move-frames-131 {
    from {
      transform: translate3d(35vw, 105vh, 0);
    }
    to {
      transform: translate3d(61vw, -113vh, 0);
    }
  }
  @keyframes move-frames-131 {
    from {
      transform: translate3d(35vw, 105vh, 0);
    }
    to {
      transform: translate3d(61vw, -113vh, 0);
    }
  }
  .circle-container:nth-child(131) .circle {
    -webkit-animation-delay: 1940ms;
            animation-delay: 1940ms;
  }
  .circle-container:nth-child(132) {
    width: 5px;
    height: 5px;
    -webkit-animation-name: move-frames-132;
            animation-name: move-frames-132;
    -webkit-animation-duration: 30555ms;
            animation-duration: 30555ms;
    -webkit-animation-delay: 967ms;
            animation-delay: 967ms;
  }
  @-webkit-keyframes move-frames-132 {
    from {
      transform: translate3d(42vw, 105vh, 0);
    }
    to {
      transform: translate3d(96vw, -106vh, 0);
    }
  }
  @keyframes move-frames-132 {
    from {
      transform: translate3d(42vw, 105vh, 0);
    }
    to {
      transform: translate3d(96vw, -106vh, 0);
    }
  }
  .circle-container:nth-child(132) .circle {
    -webkit-animation-delay: 588ms;
            animation-delay: 588ms;
  }
  .circle-container:nth-child(133) {
    width: 6px;
    height: 6px;
    -webkit-animation-name: move-frames-133;
            animation-name: move-frames-133;
    -webkit-animation-duration: 28698ms;
            animation-duration: 28698ms;
    -webkit-animation-delay: 35834ms;
            animation-delay: 35834ms;
  }
  @-webkit-keyframes move-frames-133 {
    from {
      transform: translate3d(88vw, 105vh, 0);
    }
    to {
      transform: translate3d(29vw, -122vh, 0);
    }
  }
  @keyframes move-frames-133 {
    from {
      transform: translate3d(88vw, 105vh, 0);
    }
    to {
      transform: translate3d(29vw, -122vh, 0);
    }
  }
  .circle-container:nth-child(133) .circle {
    -webkit-animation-delay: 2096ms;
            animation-delay: 2096ms;
  }
  .circle-container:nth-child(134) {
    width: 7px;
    height: 7px;
    -webkit-animation-name: move-frames-134;
            animation-name: move-frames-134;
    -webkit-animation-duration: 31017ms;
            animation-duration: 31017ms;
    -webkit-animation-delay: 34448ms;
            animation-delay: 34448ms;
  }
  @-webkit-keyframes move-frames-134 {
    from {
      transform: translate3d(27vw, 106vh, 0);
    }
    to {
      transform: translate3d(81vw, -136vh, 0);
    }
  }
  @keyframes move-frames-134 {
    from {
      transform: translate3d(27vw, 106vh, 0);
    }
    to {
      transform: translate3d(81vw, -136vh, 0);
    }
  }
  .circle-container:nth-child(134) .circle {
    -webkit-animation-delay: 691ms;
            animation-delay: 691ms;
  }
  .circle-container:nth-child(135) {
    width: 7px;
    height: 7px;
    -webkit-animation-name: move-frames-135;
            animation-name: move-frames-135;
    -webkit-animation-duration: 34212ms;
            animation-duration: 34212ms;
    -webkit-animation-delay: 17486ms;
            animation-delay: 17486ms;
  }
  @-webkit-keyframes move-frames-135 {
    from {
      transform: translate3d(82vw, 103vh, 0);
    }
    to {
      transform: translate3d(39vw, -123vh, 0);
    }
  }
  @keyframes move-frames-135 {
    from {
      transform: translate3d(82vw, 103vh, 0);
    }
    to {
      transform: translate3d(39vw, -123vh, 0);
    }
  }
  .circle-container:nth-child(135) .circle {
    -webkit-animation-delay: 3000ms;
            animation-delay: 3000ms;
  }
  .circle-container:nth-child(136) {
    width: 3px;
    height: 3px;
    -webkit-animation-name: move-frames-136;
            animation-name: move-frames-136;
    -webkit-animation-duration: 28084ms;
            animation-duration: 28084ms;
    -webkit-animation-delay: 35809ms;
            animation-delay: 35809ms;
  }
  @-webkit-keyframes move-frames-136 {
    from {
      transform: translate3d(73vw, 103vh, 0);
    }
    to {
      transform: translate3d(56vw, -122vh, 0);
    }
  }
  @keyframes move-frames-136 {
    from {
      transform: translate3d(73vw, 103vh, 0);
    }
    to {
      transform: translate3d(56vw, -122vh, 0);
    }
  }
  .circle-container:nth-child(136) .circle {
    -webkit-animation-delay: 555ms;
            animation-delay: 555ms;
  }
  .circle-container:nth-child(137) {
    width: 1px;
    height: 1px;
    -webkit-animation-name: move-frames-137;
            animation-name: move-frames-137;
    -webkit-animation-duration: 30518ms;
            animation-duration: 30518ms;
    -webkit-animation-delay: 4753ms;
            animation-delay: 4753ms;
  }
  @-webkit-keyframes move-frames-137 {
    from {
      transform: translate3d(76vw, 106vh, 0);
    }
    to {
      transform: translate3d(59vw, -126vh, 0);
    }
  }
  @keyframes move-frames-137 {
    from {
      transform: translate3d(76vw, 106vh, 0);
    }
    to {
      transform: translate3d(59vw, -126vh, 0);
    }
  }
  .circle-container:nth-child(137) .circle {
    -webkit-animation-delay: 2268ms;
            animation-delay: 2268ms;
  }
  .circle-container:nth-child(138) {
    width: 7px;
    height: 7px;
    -webkit-animation-name: move-frames-138;
            animation-name: move-frames-138;
    -webkit-animation-duration: 29461ms;
            animation-duration: 29461ms;
    -webkit-animation-delay: 32048ms;
            animation-delay: 32048ms;
  }
  @-webkit-keyframes move-frames-138 {
    from {
      transform: translate3d(82vw, 102vh, 0);
    }
    to {
      transform: translate3d(100vw, -107vh, 0);
    }
  }
  @keyframes move-frames-138 {
    from {
      transform: translate3d(82vw, 102vh, 0);
    }
    to {
      transform: translate3d(100vw, -107vh, 0);
    }
  }
  .circle-container:nth-child(138) .circle {
    -webkit-animation-delay: 3907ms;
            animation-delay: 3907ms;
  }
  .circle-container:nth-child(139) {
    width: 8px;
    height: 8px;
    -webkit-animation-name: move-frames-139;
            animation-name: move-frames-139;
    -webkit-animation-duration: 32625ms;
            animation-duration: 32625ms;
    -webkit-animation-delay: 27970ms;
            animation-delay: 27970ms;
  }
  @-webkit-keyframes move-frames-139 {
    from {
      transform: translate3d(33vw, 101vh, 0);
    }
    to {
      transform: translate3d(42vw, -118vh, 0);
    }
  }
  @keyframes move-frames-139 {
    from {
      transform: translate3d(33vw, 101vh, 0);
    }
    to {
      transform: translate3d(42vw, -118vh, 0);
    }
  }
  .circle-container:nth-child(139) .circle {
    -webkit-animation-delay: 663ms;
            animation-delay: 663ms;
  }
  .circle-container:nth-child(140) {
    width: 1px;
    height: 1px;
    -webkit-animation-name: move-frames-140;
            animation-name: move-frames-140;
    -webkit-animation-duration: 32092ms;
            animation-duration: 32092ms;
    -webkit-animation-delay: 8738ms;
            animation-delay: 8738ms;
  }
  @-webkit-keyframes move-frames-140 {
    from {
      transform: translate3d(41vw, 106vh, 0);
    }
    to {
      transform: translate3d(18vw, -122vh, 0);
    }
  }
  @keyframes move-frames-140 {
    from {
      transform: translate3d(41vw, 106vh, 0);
    }
    to {
      transform: translate3d(18vw, -122vh, 0);
    }
  }
  .circle-container:nth-child(140) .circle {
    -webkit-animation-delay: 1385ms;
            animation-delay: 1385ms;
  }
  .circle-container:nth-child(141) {
    width: 2px;
    height: 2px;
    -webkit-animation-name: move-frames-141;
            animation-name: move-frames-141;
    -webkit-animation-duration: 31477ms;
            animation-duration: 31477ms;
    -webkit-animation-delay: 5909ms;
            animation-delay: 5909ms;
  }
  @-webkit-keyframes move-frames-141 {
    from {
      transform: translate3d(29vw, 109vh, 0);
    }
    to {
      transform: translate3d(98vw, -111vh, 0);
    }
  }
  @keyframes move-frames-141 {
    from {
      transform: translate3d(29vw, 109vh, 0);
    }
    to {
      transform: translate3d(98vw, -111vh, 0);
    }
  }
  .circle-container:nth-child(141) .circle {
    -webkit-animation-delay: 1444ms;
            animation-delay: 1444ms;
  }
  .circle-container:nth-child(142) {
    width: 5px;
    height: 5px;
    -webkit-animation-name: move-frames-142;
            animation-name: move-frames-142;
    -webkit-animation-duration: 31571ms;
            animation-duration: 31571ms;
    -webkit-animation-delay: 23872ms;
            animation-delay: 23872ms;
  }
  @-webkit-keyframes move-frames-142 {
    from {
      transform: translate3d(41vw, 102vh, 0);
    }
    to {
      transform: translate3d(53vw, -106vh, 0);
    }
  }
  @keyframes move-frames-142 {
    from {
      transform: translate3d(41vw, 102vh, 0);
    }
    to {
      transform: translate3d(53vw, -106vh, 0);
    }
  }
  .circle-container:nth-child(142) .circle {
    -webkit-animation-delay: 1613ms;
            animation-delay: 1613ms;
  }
  .circle-container:nth-child(143) {
    width: 7px;
    height: 7px;
    -webkit-animation-name: move-frames-143;
            animation-name: move-frames-143;
    -webkit-animation-duration: 32878ms;
            animation-duration: 32878ms;
    -webkit-animation-delay: 6523ms;
            animation-delay: 6523ms;
  }
  @-webkit-keyframes move-frames-143 {
    from {
      transform: translate3d(1vw, 103vh, 0);
    }
    to {
      transform: translate3d(97vw, -126vh, 0);
    }
  }
  @keyframes move-frames-143 {
    from {
      transform: translate3d(1vw, 103vh, 0);
    }
    to {
      transform: translate3d(97vw, -126vh, 0);
    }
  }
  .circle-container:nth-child(143) .circle {
    -webkit-animation-delay: 2008ms;
            animation-delay: 2008ms;
  }
  .circle-container:nth-child(144) {
    width: 3px;
    height: 3px;
    -webkit-animation-name: move-frames-144;
            animation-name: move-frames-144;
    -webkit-animation-duration: 28352ms;
            animation-duration: 28352ms;
    -webkit-animation-delay: 11991ms;
            animation-delay: 11991ms;
  }
  @-webkit-keyframes move-frames-144 {
    from {
      transform: translate3d(100vw, 109vh, 0);
    }
    to {
      transform: translate3d(61vw, -121vh, 0);
    }
  }
  @keyframes move-frames-144 {
    from {
      transform: translate3d(100vw, 109vh, 0);
    }
    to {
      transform: translate3d(61vw, -121vh, 0);
    }
  }
  .circle-container:nth-child(144) .circle {
    -webkit-animation-delay: 1276ms;
            animation-delay: 1276ms;
  }
  .circle-container:nth-child(145) {
    width: 3px;
    height: 3px;
    -webkit-animation-name: move-frames-145;
            animation-name: move-frames-145;
    -webkit-animation-duration: 36690ms;
            animation-duration: 36690ms;
    -webkit-animation-delay: 23465ms;
            animation-delay: 23465ms;
  }
  @-webkit-keyframes move-frames-145 {
    from {
      transform: translate3d(62vw, 104vh, 0);
    }
    to {
      transform: translate3d(54vw, -122vh, 0);
    }
  }
  @keyframes move-frames-145 {
    from {
      transform: translate3d(62vw, 104vh, 0);
    }
    to {
      transform: translate3d(54vw, -122vh, 0);
    }
  }
  .circle-container:nth-child(145) .circle {
    -webkit-animation-delay: 2064ms;
            animation-delay: 2064ms;
  }
  .circle-container:nth-child(146) {
    width: 4px;
    height: 4px;
    -webkit-animation-name: move-frames-146;
            animation-name: move-frames-146;
    -webkit-animation-duration: 36772ms;
            animation-duration: 36772ms;
    -webkit-animation-delay: 33641ms;
            animation-delay: 33641ms;
  }
  @-webkit-keyframes move-frames-146 {
    from {
      transform: translate3d(67vw, 101vh, 0);
    }
    to {
      transform: translate3d(21vw, -109vh, 0);
    }
  }
  @keyframes move-frames-146 {
    from {
      transform: translate3d(67vw, 101vh, 0);
    }
    to {
      transform: translate3d(21vw, -109vh, 0);
    }
  }
  .circle-container:nth-child(146) .circle {
    -webkit-animation-delay: 690ms;
            animation-delay: 690ms;
  }
  .circle-container:nth-child(147) {
    width: 3px;
    height: 3px;
    -webkit-animation-name: move-frames-147;
            animation-name: move-frames-147;
    -webkit-animation-duration: 36901ms;
            animation-duration: 36901ms;
    -webkit-animation-delay: 19610ms;
            animation-delay: 19610ms;
  }
  @-webkit-keyframes move-frames-147 {
    from {
      transform: translate3d(36vw, 104vh, 0);
    }
    to {
      transform: translate3d(64vw, -122vh, 0);
    }
  }
  @keyframes move-frames-147 {
    from {
      transform: translate3d(36vw, 104vh, 0);
    }
    to {
      transform: translate3d(64vw, -122vh, 0);
    }
  }
  .circle-container:nth-child(147) .circle {
    -webkit-animation-delay: 1323ms;
            animation-delay: 1323ms;
  }
  .circle-container:nth-child(148) {
    width: 8px;
    height: 8px;
    -webkit-animation-name: move-frames-148;
            animation-name: move-frames-148;
    -webkit-animation-duration: 31384ms;
            animation-duration: 31384ms;
    -webkit-animation-delay: 6851ms;
            animation-delay: 6851ms;
  }
  @-webkit-keyframes move-frames-148 {
    from {
      transform: translate3d(41vw, 105vh, 0);
    }
    to {
      transform: translate3d(44vw, -131vh, 0);
    }
  }
  @keyframes move-frames-148 {
    from {
      transform: translate3d(41vw, 105vh, 0);
    }
    to {
      transform: translate3d(44vw, -131vh, 0);
    }
  }
  .circle-container:nth-child(148) .circle {
    -webkit-animation-delay: 2953ms;
            animation-delay: 2953ms;
  }
  .circle-container:nth-child(149) {
    width: 2px;
    height: 2px;
    -webkit-animation-name: move-frames-149;
            animation-name: move-frames-149;
    -webkit-animation-duration: 28916ms;
            animation-duration: 28916ms;
    -webkit-animation-delay: 6111ms;
            animation-delay: 6111ms;
  }
  @-webkit-keyframes move-frames-149 {
    from {
      transform: translate3d(100vw, 104vh, 0);
    }
    to {
      transform: translate3d(28vw, -132vh, 0);
    }
  }
  @keyframes move-frames-149 {
    from {
      transform: translate3d(100vw, 104vh, 0);
    }
    to {
      transform: translate3d(28vw, -132vh, 0);
    }
  }
  .circle-container:nth-child(149) .circle {
    -webkit-animation-delay: 2860ms;
            animation-delay: 2860ms;
  }
  .circle-container:nth-child(150) {
    width: 6px;
    height: 6px;
    -webkit-animation-name: move-frames-150;
            animation-name: move-frames-150;
    -webkit-animation-duration: 28634ms;
            animation-duration: 28634ms;
    -webkit-animation-delay: 19288ms;
            animation-delay: 19288ms;
  }
  @-webkit-keyframes move-frames-150 {
    from {
      transform: translate3d(59vw, 101vh, 0);
    }
    to {
      transform: translate3d(38vw, -122vh, 0);
    }
  }
  @keyframes move-frames-150 {
    from {
      transform: translate3d(59vw, 101vh, 0);
    }
    to {
      transform: translate3d(38vw, -122vh, 0);
    }
  }
  .circle-container:nth-child(150) .circle {
    -webkit-animation-delay: 2661ms;
            animation-delay: 2661ms;
  }
  .circle-container:nth-child(151) {
    width: 3px;
    height: 3px;
    -webkit-animation-name: move-frames-151;
            animation-name: move-frames-151;
    -webkit-animation-duration: 28618ms;
            animation-duration: 28618ms;
    -webkit-animation-delay: 8367ms;
            animation-delay: 8367ms;
  }
  @-webkit-keyframes move-frames-151 {
    from {
      transform: translate3d(56vw, 109vh, 0);
    }
    to {
      transform: translate3d(41vw, -116vh, 0);
    }
  }
  @keyframes move-frames-151 {
    from {
      transform: translate3d(56vw, 109vh, 0);
    }
    to {
      transform: translate3d(41vw, -116vh, 0);
    }
  }
  .circle-container:nth-child(151) .circle {
    -webkit-animation-delay: 334ms;
            animation-delay: 334ms;
  }
  .circle-container:nth-child(152) {
    width: 5px;
    height: 5px;
    -webkit-animation-name: move-frames-152;
            animation-name: move-frames-152;
    -webkit-animation-duration: 34011ms;
            animation-duration: 34011ms;
    -webkit-animation-delay: 8249ms;
            animation-delay: 8249ms;
  }
  @-webkit-keyframes move-frames-152 {
    from {
      transform: translate3d(25vw, 106vh, 0);
    }
    to {
      transform: translate3d(31vw, -122vh, 0);
    }
  }
  @keyframes move-frames-152 {
    from {
      transform: translate3d(25vw, 106vh, 0);
    }
    to {
      transform: translate3d(31vw, -122vh, 0);
    }
  }
  .circle-container:nth-child(152) .circle {
    -webkit-animation-delay: 2713ms;
            animation-delay: 2713ms;
  }
  .circle-container:nth-child(153) {
    width: 3px;
    height: 3px;
    -webkit-animation-name: move-frames-153;
            animation-name: move-frames-153;
    -webkit-animation-duration: 30715ms;
            animation-duration: 30715ms;
    -webkit-animation-delay: 14261ms;
            animation-delay: 14261ms;
  }
  @-webkit-keyframes move-frames-153 {
    from {
      transform: translate3d(51vw, 103vh, 0);
    }
    to {
      transform: translate3d(11vw, -113vh, 0);
    }
  }
  @keyframes move-frames-153 {
    from {
      transform: translate3d(51vw, 103vh, 0);
    }
    to {
      transform: translate3d(11vw, -113vh, 0);
    }
  }
  .circle-container:nth-child(153) .circle {
    -webkit-animation-delay: 3091ms;
            animation-delay: 3091ms;
  }
  .circle-container:nth-child(154) {
    width: 7px;
    height: 7px;
    -webkit-animation-name: move-frames-154;
            animation-name: move-frames-154;
    -webkit-animation-duration: 29187ms;
            animation-duration: 29187ms;
    -webkit-animation-delay: 27827ms;
            animation-delay: 27827ms;
  }
  @-webkit-keyframes move-frames-154 {
    from {
      transform: translate3d(68vw, 106vh, 0);
    }
    to {
      transform: translate3d(1vw, -136vh, 0);
    }
  }
  @keyframes move-frames-154 {
    from {
      transform: translate3d(68vw, 106vh, 0);
    }
    to {
      transform: translate3d(1vw, -136vh, 0);
    }
  }
  .circle-container:nth-child(154) .circle {
    -webkit-animation-delay: 2926ms;
            animation-delay: 2926ms;
  }
  .circle-container:nth-child(155) {
    width: 6px;
    height: 6px;
    -webkit-animation-name: move-frames-155;
            animation-name: move-frames-155;
    -webkit-animation-duration: 28312ms;
            animation-duration: 28312ms;
    -webkit-animation-delay: 36119ms;
            animation-delay: 36119ms;
  }
  @-webkit-keyframes move-frames-155 {
    from {
      transform: translate3d(78vw, 103vh, 0);
    }
    to {
      transform: translate3d(77vw, -119vh, 0);
    }
  }
  @keyframes move-frames-155 {
    from {
      transform: translate3d(78vw, 103vh, 0);
    }
    to {
      transform: translate3d(77vw, -119vh, 0);
    }
  }
  .circle-container:nth-child(155) .circle {
    -webkit-animation-delay: 2208ms;
            animation-delay: 2208ms;
  }
  .circle-container:nth-child(156) {
    width: 1px;
    height: 1px;
    -webkit-animation-name: move-frames-156;
            animation-name: move-frames-156;
    -webkit-animation-duration: 31547ms;
            animation-duration: 31547ms;
    -webkit-animation-delay: 17906ms;
            animation-delay: 17906ms;
  }
  @-webkit-keyframes move-frames-156 {
    from {
      transform: translate3d(67vw, 102vh, 0);
    }
    to {
      transform: translate3d(9vw, -114vh, 0);
    }
  }
  @keyframes move-frames-156 {
    from {
      transform: translate3d(67vw, 102vh, 0);
    }
    to {
      transform: translate3d(9vw, -114vh, 0);
    }
  }
  .circle-container:nth-child(156) .circle {
    -webkit-animation-delay: 1237ms;
            animation-delay: 1237ms;
  }
  .circle-container:nth-child(157) {
    width: 7px;
    height: 7px;
    -webkit-animation-name: move-frames-157;
            animation-name: move-frames-157;
    -webkit-animation-duration: 32013ms;
            animation-duration: 32013ms;
    -webkit-animation-delay: 36428ms;
            animation-delay: 36428ms;
  }
  @-webkit-keyframes move-frames-157 {
    from {
      transform: translate3d(70vw, 110vh, 0);
    }
    to {
      transform: translate3d(91vw, -125vh, 0);
    }
  }
  @keyframes move-frames-157 {
    from {
      transform: translate3d(70vw, 110vh, 0);
    }
    to {
      transform: translate3d(91vw, -125vh, 0);
    }
  }
  .circle-container:nth-child(157) .circle {
    -webkit-animation-delay: 1318ms;
            animation-delay: 1318ms;
  }
  .circle-container:nth-child(158) {
    width: 3px;
    height: 3px;
    -webkit-animation-name: move-frames-158;
            animation-name: move-frames-158;
    -webkit-animation-duration: 32206ms;
            animation-duration: 32206ms;
    -webkit-animation-delay: 400ms;
            animation-delay: 400ms;
  }
  @-webkit-keyframes move-frames-158 {
    from {
      transform: translate3d(31vw, 101vh, 0);
    }
    to {
      transform: translate3d(25vw, -121vh, 0);
    }
  }
  @keyframes move-frames-158 {
    from {
      transform: translate3d(31vw, 101vh, 0);
    }
    to {
      transform: translate3d(25vw, -121vh, 0);
    }
  }
  .circle-container:nth-child(158) .circle {
    -webkit-animation-delay: 3813ms;
            animation-delay: 3813ms;
  }
  .circle-container:nth-child(159) {
    width: 7px;
    height: 7px;
    -webkit-animation-name: move-frames-159;
            animation-name: move-frames-159;
    -webkit-animation-duration: 34559ms;
            animation-duration: 34559ms;
    -webkit-animation-delay: 21164ms;
            animation-delay: 21164ms;
  }
  @-webkit-keyframes move-frames-159 {
    from {
      transform: translate3d(5vw, 102vh, 0);
    }
    to {
      transform: translate3d(84vw, -109vh, 0);
    }
  }
  @keyframes move-frames-159 {
    from {
      transform: translate3d(5vw, 102vh, 0);
    }
    to {
      transform: translate3d(84vw, -109vh, 0);
    }
  }
  .circle-container:nth-child(159) .circle {
    -webkit-animation-delay: 3425ms;
            animation-delay: 3425ms;
  }
  .circle-container:nth-child(160) {
    width: 8px;
    height: 8px;
    -webkit-animation-name: move-frames-160;
            animation-name: move-frames-160;
    -webkit-animation-duration: 28789ms;
            animation-duration: 28789ms;
    -webkit-animation-delay: 26178ms;
            animation-delay: 26178ms;
  }
  @-webkit-keyframes move-frames-160 {
    from {
      transform: translate3d(58vw, 110vh, 0);
    }
    to {
      transform: translate3d(43vw, -137vh, 0);
    }
  }
  @keyframes move-frames-160 {
    from {
      transform: translate3d(58vw, 110vh, 0);
    }
    to {
      transform: translate3d(43vw, -137vh, 0);
    }
  }
  .circle-container:nth-child(160) .circle {
    -webkit-animation-delay: 2021ms;
            animation-delay: 2021ms;
  }
  .circle-container:nth-child(161) {
    width: 2px;
    height: 2px;
    -webkit-animation-name: move-frames-161;
            animation-name: move-frames-161;
    -webkit-animation-duration: 28162ms;
            animation-duration: 28162ms;
    -webkit-animation-delay: 20009ms;
            animation-delay: 20009ms;
  }
  @-webkit-keyframes move-frames-161 {
    from {
      transform: translate3d(3vw, 101vh, 0);
    }
    to {
      transform: translate3d(70vw, -108vh, 0);
    }
  }
  @keyframes move-frames-161 {
    from {
      transform: translate3d(3vw, 101vh, 0);
    }
    to {
      transform: translate3d(70vw, -108vh, 0);
    }
  }
  .circle-container:nth-child(161) .circle {
    -webkit-animation-delay: 2420ms;
            animation-delay: 2420ms;
  }
  .circle-container:nth-child(162) {
    width: 1px;
    height: 1px;
    -webkit-animation-name: move-frames-162;
            animation-name: move-frames-162;
    -webkit-animation-duration: 30753ms;
            animation-duration: 30753ms;
    -webkit-animation-delay: 27791ms;
            animation-delay: 27791ms;
  }
  @-webkit-keyframes move-frames-162 {
    from {
      transform: translate3d(11vw, 106vh, 0);
    }
    to {
      transform: translate3d(88vw, -122vh, 0);
    }
  }
  @keyframes move-frames-162 {
    from {
      transform: translate3d(11vw, 106vh, 0);
    }
    to {
      transform: translate3d(88vw, -122vh, 0);
    }
  }
  .circle-container:nth-child(162) .circle {
    -webkit-animation-delay: 3192ms;
            animation-delay: 3192ms;
  }
  .circle-container:nth-child(163) {
    width: 6px;
    height: 6px;
    -webkit-animation-name: move-frames-163;
            animation-name: move-frames-163;
    -webkit-animation-duration: 34645ms;
            animation-duration: 34645ms;
    -webkit-animation-delay: 18977ms;
            animation-delay: 18977ms;
  }
  @-webkit-keyframes move-frames-163 {
    from {
      transform: translate3d(32vw, 105vh, 0);
    }
    to {
      transform: translate3d(28vw, -125vh, 0);
    }
  }
  @keyframes move-frames-163 {
    from {
      transform: translate3d(32vw, 105vh, 0);
    }
    to {
      transform: translate3d(28vw, -125vh, 0);
    }
  }
  .circle-container:nth-child(163) .circle {
    -webkit-animation-delay: 1419ms;
            animation-delay: 1419ms;
  }
  .circle-container:nth-child(164) {
    width: 8px;
    height: 8px;
    -webkit-animation-name: move-frames-164;
            animation-name: move-frames-164;
    -webkit-animation-duration: 32580ms;
            animation-duration: 32580ms;
    -webkit-animation-delay: 17297ms;
            animation-delay: 17297ms;
  }
  @-webkit-keyframes move-frames-164 {
    from {
      transform: translate3d(37vw, 103vh, 0);
    }
    to {
      transform: translate3d(76vw, -107vh, 0);
    }
  }
  @keyframes move-frames-164 {
    from {
      transform: translate3d(37vw, 103vh, 0);
    }
    to {
      transform: translate3d(76vw, -107vh, 0);
    }
  }
  .circle-container:nth-child(164) .circle {
    -webkit-animation-delay: 1319ms;
            animation-delay: 1319ms;
  }
  .circle-container:nth-child(165) {
    width: 4px;
    height: 4px;
    -webkit-animation-name: move-frames-165;
            animation-name: move-frames-165;
    -webkit-animation-duration: 30528ms;
            animation-duration: 30528ms;
    -webkit-animation-delay: 33867ms;
            animation-delay: 33867ms;
  }
  @-webkit-keyframes move-frames-165 {
    from {
      transform: translate3d(75vw, 101vh, 0);
    }
    to {
      transform: translate3d(31vw, -120vh, 0);
    }
  }
  @keyframes move-frames-165 {
    from {
      transform: translate3d(75vw, 101vh, 0);
    }
    to {
      transform: translate3d(31vw, -120vh, 0);
    }
  }
  .circle-container:nth-child(165) .circle {
    -webkit-animation-delay: 3347ms;
            animation-delay: 3347ms;
  }
  .circle-container:nth-child(166) {
    width: 8px;
    height: 8px;
    -webkit-animation-name: move-frames-166;
            animation-name: move-frames-166;
    -webkit-animation-duration: 32636ms;
            animation-duration: 32636ms;
    -webkit-animation-delay: 28617ms;
            animation-delay: 28617ms;
  }
  @-webkit-keyframes move-frames-166 {
    from {
      transform: translate3d(70vw, 110vh, 0);
    }
    to {
      transform: translate3d(87vw, -122vh, 0);
    }
  }
  @keyframes move-frames-166 {
    from {
      transform: translate3d(70vw, 110vh, 0);
    }
    to {
      transform: translate3d(87vw, -122vh, 0);
    }
  }
  .circle-container:nth-child(166) .circle {
    -webkit-animation-delay: 3212ms;
            animation-delay: 3212ms;
  }
  .circle-container:nth-child(167) {
    width: 1px;
    height: 1px;
    -webkit-animation-name: move-frames-167;
            animation-name: move-frames-167;
    -webkit-animation-duration: 30344ms;
            animation-duration: 30344ms;
    -webkit-animation-delay: 22179ms;
            animation-delay: 22179ms;
  }
  @-webkit-keyframes move-frames-167 {
    from {
      transform: translate3d(6vw, 108vh, 0);
    }
    to {
      transform: translate3d(8vw, -114vh, 0);
    }
  }
  @keyframes move-frames-167 {
    from {
      transform: translate3d(6vw, 108vh, 0);
    }
    to {
      transform: translate3d(8vw, -114vh, 0);
    }
  }
  .circle-container:nth-child(167) .circle {
    -webkit-animation-delay: 31ms;
            animation-delay: 31ms;
  }
  .circle-container:nth-child(168) {
    width: 1px;
    height: 1px;
    -webkit-animation-name: move-frames-168;
            animation-name: move-frames-168;
    -webkit-animation-duration: 35503ms;
            animation-duration: 35503ms;
    -webkit-animation-delay: 5065ms;
            animation-delay: 5065ms;
  }
  @-webkit-keyframes move-frames-168 {
    from {
      transform: translate3d(57vw, 101vh, 0);
    }
    to {
      transform: translate3d(15vw, -111vh, 0);
    }
  }
  @keyframes move-frames-168 {
    from {
      transform: translate3d(57vw, 101vh, 0);
    }
    to {
      transform: translate3d(15vw, -111vh, 0);
    }
  }
  .circle-container:nth-child(168) .circle {
    -webkit-animation-delay: 2271ms;
            animation-delay: 2271ms;
  }
  .circle-container:nth-child(169) {
    width: 3px;
    height: 3px;
    -webkit-animation-name: move-frames-169;
            animation-name: move-frames-169;
    -webkit-animation-duration: 28125ms;
            animation-duration: 28125ms;
    -webkit-animation-delay: 5832ms;
            animation-delay: 5832ms;
  }
  @-webkit-keyframes move-frames-169 {
    from {
      transform: translate3d(20vw, 105vh, 0);
    }
    to {
      transform: translate3d(93vw, -114vh, 0);
    }
  }
  @keyframes move-frames-169 {
    from {
      transform: translate3d(20vw, 105vh, 0);
    }
    to {
      transform: translate3d(93vw, -114vh, 0);
    }
  }
  .circle-container:nth-child(169) .circle {
    -webkit-animation-delay: 2013ms;
            animation-delay: 2013ms;
  }
  .circle-container:nth-child(170) {
    width: 6px;
    height: 6px;
    -webkit-animation-name: move-frames-170;
            animation-name: move-frames-170;
    -webkit-animation-duration: 28581ms;
            animation-duration: 28581ms;
    -webkit-animation-delay: 29796ms;
            animation-delay: 29796ms;
  }
  @-webkit-keyframes move-frames-170 {
    from {
      transform: translate3d(20vw, 105vh, 0);
    }
    to {
      transform: translate3d(85vw, -129vh, 0);
    }
  }
  @keyframes move-frames-170 {
    from {
      transform: translate3d(20vw, 105vh, 0);
    }
    to {
      transform: translate3d(85vw, -129vh, 0);
    }
  }
  .circle-container:nth-child(170) .circle {
    -webkit-animation-delay: 925ms;
            animation-delay: 925ms;
  }
  .circle-container:nth-child(171) {
    width: 3px;
    height: 3px;
    -webkit-animation-name: move-frames-171;
            animation-name: move-frames-171;
    -webkit-animation-duration: 32655ms;
            animation-duration: 32655ms;
    -webkit-animation-delay: 35246ms;
            animation-delay: 35246ms;
  }
  @-webkit-keyframes move-frames-171 {
    from {
      transform: translate3d(43vw, 106vh, 0);
    }
    to {
      transform: translate3d(91vw, -133vh, 0);
    }
  }
  @keyframes move-frames-171 {
    from {
      transform: translate3d(43vw, 106vh, 0);
    }
    to {
      transform: translate3d(91vw, -133vh, 0);
    }
  }
  .circle-container:nth-child(171) .circle {
    -webkit-animation-delay: 3576ms;
            animation-delay: 3576ms;
  }
  .circle-container:nth-child(172) {
    width: 1px;
    height: 1px;
    -webkit-animation-name: move-frames-172;
            animation-name: move-frames-172;
    -webkit-animation-duration: 34669ms;
            animation-duration: 34669ms;
    -webkit-animation-delay: 11787ms;
            animation-delay: 11787ms;
  }
  @-webkit-keyframes move-frames-172 {
    from {
      transform: translate3d(68vw, 109vh, 0);
    }
    to {
      transform: translate3d(98vw, -129vh, 0);
    }
  }
  @keyframes move-frames-172 {
    from {
      transform: translate3d(68vw, 109vh, 0);
    }
    to {
      transform: translate3d(98vw, -129vh, 0);
    }
  }
  .circle-container:nth-child(172) .circle {
    -webkit-animation-delay: 3751ms;
            animation-delay: 3751ms;
  }
  .circle-container:nth-child(173) {
    width: 5px;
    height: 5px;
    -webkit-animation-name: move-frames-173;
            animation-name: move-frames-173;
    -webkit-animation-duration: 33605ms;
            animation-duration: 33605ms;
    -webkit-animation-delay: 7953ms;
            animation-delay: 7953ms;
  }
  @-webkit-keyframes move-frames-173 {
    from {
      transform: translate3d(55vw, 101vh, 0);
    }
    to {
      transform: translate3d(33vw, -116vh, 0);
    }
  }
  @keyframes move-frames-173 {
    from {
      transform: translate3d(55vw, 101vh, 0);
    }
    to {
      transform: translate3d(33vw, -116vh, 0);
    }
  }
  .circle-container:nth-child(173) .circle {
    -webkit-animation-delay: 382ms;
            animation-delay: 382ms;
  }
  .circle-container:nth-child(174) {
    width: 4px;
    height: 4px;
    -webkit-animation-name: move-frames-174;
            animation-name: move-frames-174;
    -webkit-animation-duration: 29499ms;
            animation-duration: 29499ms;
    -webkit-animation-delay: 12065ms;
            animation-delay: 12065ms;
  }
  @-webkit-keyframes move-frames-174 {
    from {
      transform: translate3d(28vw, 109vh, 0);
    }
    to {
      transform: translate3d(58vw, -110vh, 0);
    }
  }
  @keyframes move-frames-174 {
    from {
      transform: translate3d(28vw, 109vh, 0);
    }
    to {
      transform: translate3d(58vw, -110vh, 0);
    }
  }
  .circle-container:nth-child(174) .circle {
    -webkit-animation-delay: 1803ms;
            animation-delay: 1803ms;
  }
  .circle-container:nth-child(175) {
    width: 8px;
    height: 8px;
    -webkit-animation-name: move-frames-175;
            animation-name: move-frames-175;
    -webkit-animation-duration: 32215ms;
            animation-duration: 32215ms;
    -webkit-animation-delay: 30370ms;
            animation-delay: 30370ms;
  }
  @-webkit-keyframes move-frames-175 {
    from {
      transform: translate3d(25vw, 103vh, 0);
    }
    to {
      transform: translate3d(56vw, -122vh, 0);
    }
  }
  @keyframes move-frames-175 {
    from {
      transform: translate3d(25vw, 103vh, 0);
    }
    to {
      transform: translate3d(56vw, -122vh, 0);
    }
  }
  .circle-container:nth-child(175) .circle {
    -webkit-animation-delay: 3407ms;
            animation-delay: 3407ms;
  }
  .circle-container:nth-child(176) {
    width: 3px;
    height: 3px;
    -webkit-animation-name: move-frames-176;
            animation-name: move-frames-176;
    -webkit-animation-duration: 31272ms;
            animation-duration: 31272ms;
    -webkit-animation-delay: 4202ms;
            animation-delay: 4202ms;
  }
  @-webkit-keyframes move-frames-176 {
    from {
      transform: translate3d(24vw, 103vh, 0);
    }
    to {
      transform: translate3d(18vw, -130vh, 0);
    }
  }
  @keyframes move-frames-176 {
    from {
      transform: translate3d(24vw, 103vh, 0);
    }
    to {
      transform: translate3d(18vw, -130vh, 0);
    }
  }
  .circle-container:nth-child(176) .circle {
    -webkit-animation-delay: 1391ms;
            animation-delay: 1391ms;
  }
  .circle-container:nth-child(177) {
    width: 8px;
    height: 8px;
    -webkit-animation-name: move-frames-177;
            animation-name: move-frames-177;
    -webkit-animation-duration: 28277ms;
            animation-duration: 28277ms;
    -webkit-animation-delay: 20927ms;
            animation-delay: 20927ms;
  }
  @-webkit-keyframes move-frames-177 {
    from {
      transform: translate3d(5vw, 108vh, 0);
    }
    to {
      transform: translate3d(74vw, -122vh, 0);
    }
  }
  @keyframes move-frames-177 {
    from {
      transform: translate3d(5vw, 108vh, 0);
    }
    to {
      transform: translate3d(74vw, -122vh, 0);
    }
  }
  .circle-container:nth-child(177) .circle {
    -webkit-animation-delay: 1492ms;
            animation-delay: 1492ms;
  }
  .circle-container:nth-child(178) {
    width: 3px;
    height: 3px;
    -webkit-animation-name: move-frames-178;
            animation-name: move-frames-178;
    -webkit-animation-duration: 30145ms;
            animation-duration: 30145ms;
    -webkit-animation-delay: 10864ms;
            animation-delay: 10864ms;
  }
  @-webkit-keyframes move-frames-178 {
    from {
      transform: translate3d(88vw, 106vh, 0);
    }
    to {
      transform: translate3d(35vw, -114vh, 0);
    }
  }
  @keyframes move-frames-178 {
    from {
      transform: translate3d(88vw, 106vh, 0);
    }
    to {
      transform: translate3d(35vw, -114vh, 0);
    }
  }
  .circle-container:nth-child(178) .circle {
    -webkit-animation-delay: 2502ms;
            animation-delay: 2502ms;
  }
  .circle-container:nth-child(179) {
    width: 6px;
    height: 6px;
    -webkit-animation-name: move-frames-179;
            animation-name: move-frames-179;
    -webkit-animation-duration: 29345ms;
            animation-duration: 29345ms;
    -webkit-animation-delay: 31626ms;
            animation-delay: 31626ms;
  }
  @-webkit-keyframes move-frames-179 {
    from {
      transform: translate3d(11vw, 104vh, 0);
    }
    to {
      transform: translate3d(5vw, -108vh, 0);
    }
  }
  @keyframes move-frames-179 {
    from {
      transform: translate3d(11vw, 104vh, 0);
    }
    to {
      transform: translate3d(5vw, -108vh, 0);
    }
  }
  .circle-container:nth-child(179) .circle {
    -webkit-animation-delay: 743ms;
            animation-delay: 743ms;
  }
  .circle-container:nth-child(180) {
    width: 6px;
    height: 6px;
    -webkit-animation-name: move-frames-180;
            animation-name: move-frames-180;
    -webkit-animation-duration: 28976ms;
            animation-duration: 28976ms;
    -webkit-animation-delay: 11333ms;
            animation-delay: 11333ms;
  }
  @-webkit-keyframes move-frames-180 {
    from {
      transform: translate3d(20vw, 105vh, 0);
    }
    to {
      transform: translate3d(99vw, -112vh, 0);
    }
  }
  @keyframes move-frames-180 {
    from {
      transform: translate3d(20vw, 105vh, 0);
    }
    to {
      transform: translate3d(99vw, -112vh, 0);
    }
  }
  .circle-container:nth-child(180) .circle {
    -webkit-animation-delay: 1560ms;
            animation-delay: 1560ms;
  }
  .circle-container:nth-child(181) {
    width: 5px;
    height: 5px;
    -webkit-animation-name: move-frames-181;
            animation-name: move-frames-181;
    -webkit-animation-duration: 36413ms;
            animation-duration: 36413ms;
    -webkit-animation-delay: 12746ms;
            animation-delay: 12746ms;
  }
  @-webkit-keyframes move-frames-181 {
    from {
      transform: translate3d(31vw, 101vh, 0);
    }
    to {
      transform: translate3d(8vw, -106vh, 0);
    }
  }
  @keyframes move-frames-181 {
    from {
      transform: translate3d(31vw, 101vh, 0);
    }
    to {
      transform: translate3d(8vw, -106vh, 0);
    }
  }
  .circle-container:nth-child(181) .circle {
    -webkit-animation-delay: 3357ms;
            animation-delay: 3357ms;
  }
  .circle-container:nth-child(182) {
    width: 5px;
    height: 5px;
    -webkit-animation-name: move-frames-182;
            animation-name: move-frames-182;
    -webkit-animation-duration: 30776ms;
            animation-duration: 30776ms;
    -webkit-animation-delay: 594ms;
            animation-delay: 594ms;
  }
  @-webkit-keyframes move-frames-182 {
    from {
      transform: translate3d(11vw, 106vh, 0);
    }
    to {
      transform: translate3d(12vw, -123vh, 0);
    }
  }
  @keyframes move-frames-182 {
    from {
      transform: translate3d(11vw, 106vh, 0);
    }
    to {
      transform: translate3d(12vw, -123vh, 0);
    }
  }
  .circle-container:nth-child(182) .circle {
    -webkit-animation-delay: 1023ms;
            animation-delay: 1023ms;
  }
  .circle-container:nth-child(183) {
    width: 8px;
    height: 8px;
    -webkit-animation-name: move-frames-183;
            animation-name: move-frames-183;
    -webkit-animation-duration: 31620ms;
            animation-duration: 31620ms;
    -webkit-animation-delay: 28721ms;
            animation-delay: 28721ms;
  }
  @-webkit-keyframes move-frames-183 {
    from {
      transform: translate3d(38vw, 101vh, 0);
    }
    to {
      transform: translate3d(53vw, -124vh, 0);
    }
  }
  @keyframes move-frames-183 {
    from {
      transform: translate3d(38vw, 101vh, 0);
    }
    to {
      transform: translate3d(53vw, -124vh, 0);
    }
  }
  .circle-container:nth-child(183) .circle {
    -webkit-animation-delay: 3066ms;
            animation-delay: 3066ms;
  }
  .circle-container:nth-child(184) {
    width: 7px;
    height: 7px;
    -webkit-animation-name: move-frames-184;
            animation-name: move-frames-184;
    -webkit-animation-duration: 35818ms;
            animation-duration: 35818ms;
    -webkit-animation-delay: 26946ms;
            animation-delay: 26946ms;
  }
  @-webkit-keyframes move-frames-184 {
    from {
      transform: translate3d(44vw, 106vh, 0);
    }
    to {
      transform: translate3d(71vw, -113vh, 0);
    }
  }
  @keyframes move-frames-184 {
    from {
      transform: translate3d(44vw, 106vh, 0);
    }
    to {
      transform: translate3d(71vw, -113vh, 0);
    }
  }
  .circle-container:nth-child(184) .circle {
    -webkit-animation-delay: 1954ms;
            animation-delay: 1954ms;
  }
  .circle-container:nth-child(185) {
    width: 1px;
    height: 1px;
    -webkit-animation-name: move-frames-185;
            animation-name: move-frames-185;
    -webkit-animation-duration: 34819ms;
            animation-duration: 34819ms;
    -webkit-animation-delay: 6976ms;
            animation-delay: 6976ms;
  }
  @-webkit-keyframes move-frames-185 {
    from {
      transform: translate3d(21vw, 104vh, 0);
    }
    to {
      transform: translate3d(42vw, -120vh, 0);
    }
  }
  @keyframes move-frames-185 {
    from {
      transform: translate3d(21vw, 104vh, 0);
    }
    to {
      transform: translate3d(42vw, -120vh, 0);
    }
  }
  .circle-container:nth-child(185) .circle {
    -webkit-animation-delay: 871ms;
            animation-delay: 871ms;
  }
  .circle-container:nth-child(186) {
    width: 1px;
    height: 1px;
    -webkit-animation-name: move-frames-186;
            animation-name: move-frames-186;
    -webkit-animation-duration: 32657ms;
            animation-duration: 32657ms;
    -webkit-animation-delay: 23266ms;
            animation-delay: 23266ms;
  }
  @-webkit-keyframes move-frames-186 {
    from {
      transform: translate3d(65vw, 108vh, 0);
    }
    to {
      transform: translate3d(46vw, -138vh, 0);
    }
  }
  @keyframes move-frames-186 {
    from {
      transform: translate3d(65vw, 108vh, 0);
    }
    to {
      transform: translate3d(46vw, -138vh, 0);
    }
  }
  .circle-container:nth-child(186) .circle {
    -webkit-animation-delay: 3274ms;
            animation-delay: 3274ms;
  }
  .circle-container:nth-child(187) {
    width: 3px;
    height: 3px;
    -webkit-animation-name: move-frames-187;
            animation-name: move-frames-187;
    -webkit-animation-duration: 34332ms;
            animation-duration: 34332ms;
    -webkit-animation-delay: 32583ms;
            animation-delay: 32583ms;
  }
  @-webkit-keyframes move-frames-187 {
    from {
      transform: translate3d(21vw, 103vh, 0);
    }
    to {
      transform: translate3d(94vw, -122vh, 0);
    }
  }
  @keyframes move-frames-187 {
    from {
      transform: translate3d(21vw, 103vh, 0);
    }
    to {
      transform: translate3d(94vw, -122vh, 0);
    }
  }
  .circle-container:nth-child(187) .circle {
    -webkit-animation-delay: 617ms;
            animation-delay: 617ms;
  }
  .circle-container:nth-child(188) {
    width: 7px;
    height: 7px;
    -webkit-animation-name: move-frames-188;
            animation-name: move-frames-188;
    -webkit-animation-duration: 28886ms;
            animation-duration: 28886ms;
    -webkit-animation-delay: 26243ms;
            animation-delay: 26243ms;
  }
  @-webkit-keyframes move-frames-188 {
    from {
      transform: translate3d(26vw, 101vh, 0);
    }
    to {
      transform: translate3d(79vw, -131vh, 0);
    }
  }
  @keyframes move-frames-188 {
    from {
      transform: translate3d(26vw, 101vh, 0);
    }
    to {
      transform: translate3d(79vw, -131vh, 0);
    }
  }
  .circle-container:nth-child(188) .circle {
    -webkit-animation-delay: 251ms;
            animation-delay: 251ms;
  }
  .circle-container:nth-child(189) {
    width: 5px;
    height: 5px;
    -webkit-animation-name: move-frames-189;
            animation-name: move-frames-189;
    -webkit-animation-duration: 33956ms;
            animation-duration: 33956ms;
    -webkit-animation-delay: 26219ms;
            animation-delay: 26219ms;
  }
  @-webkit-keyframes move-frames-189 {
    from {
      transform: translate3d(66vw, 103vh, 0);
    }
    to {
      transform: translate3d(11vw, -129vh, 0);
    }
  }
  @keyframes move-frames-189 {
    from {
      transform: translate3d(66vw, 103vh, 0);
    }
    to {
      transform: translate3d(11vw, -129vh, 0);
    }
  }
  .circle-container:nth-child(189) .circle {
    -webkit-animation-delay: 3715ms;
            animation-delay: 3715ms;
  }
  .circle-container:nth-child(190) {
    width: 2px;
    height: 2px;
    -webkit-animation-name: move-frames-190;
            animation-name: move-frames-190;
    -webkit-animation-duration: 30457ms;
            animation-duration: 30457ms;
    -webkit-animation-delay: 2557ms;
            animation-delay: 2557ms;
  }
  @-webkit-keyframes move-frames-190 {
    from {
      transform: translate3d(49vw, 107vh, 0);
    }
    to {
      transform: translate3d(14vw, -118vh, 0);
    }
  }
  @keyframes move-frames-190 {
    from {
      transform: translate3d(49vw, 107vh, 0);
    }
    to {
      transform: translate3d(14vw, -118vh, 0);
    }
  }
  .circle-container:nth-child(190) .circle {
    -webkit-animation-delay: 3057ms;
            animation-delay: 3057ms;
  }
  .circle-container:nth-child(191) {
    width: 8px;
    height: 8px;
    -webkit-animation-name: move-frames-191;
            animation-name: move-frames-191;
    -webkit-animation-duration: 36916ms;
            animation-duration: 36916ms;
    -webkit-animation-delay: 2552ms;
            animation-delay: 2552ms;
  }
  @-webkit-keyframes move-frames-191 {
    from {
      transform: translate3d(89vw, 105vh, 0);
    }
    to {
      transform: translate3d(29vw, -130vh, 0);
    }
  }
  @keyframes move-frames-191 {
    from {
      transform: translate3d(89vw, 105vh, 0);
    }
    to {
      transform: translate3d(29vw, -130vh, 0);
    }
  }
  .circle-container:nth-child(191) .circle {
    -webkit-animation-delay: 3020ms;
            animation-delay: 3020ms;
  }
  .circle-container:nth-child(192) {
    width: 1px;
    height: 1px;
    -webkit-animation-name: move-frames-192;
            animation-name: move-frames-192;
    -webkit-animation-duration: 33389ms;
            animation-duration: 33389ms;
    -webkit-animation-delay: 1642ms;
            animation-delay: 1642ms;
  }
  @-webkit-keyframes move-frames-192 {
    from {
      transform: translate3d(70vw, 106vh, 0);
    }
    to {
      transform: translate3d(76vw, -112vh, 0);
    }
  }
  @keyframes move-frames-192 {
    from {
      transform: translate3d(70vw, 106vh, 0);
    }
    to {
      transform: translate3d(76vw, -112vh, 0);
    }
  }
  .circle-container:nth-child(192) .circle {
    -webkit-animation-delay: 1189ms;
            animation-delay: 1189ms;
  }
  .circle-container:nth-child(193) {
    width: 1px;
    height: 1px;
    -webkit-animation-name: move-frames-193;
            animation-name: move-frames-193;
    -webkit-animation-duration: 29343ms;
            animation-duration: 29343ms;
    -webkit-animation-delay: 20198ms;
            animation-delay: 20198ms;
  }
  @-webkit-keyframes move-frames-193 {
    from {
      transform: translate3d(82vw, 108vh, 0);
    }
    to {
      transform: translate3d(2vw, -110vh, 0);
    }
  }
  @keyframes move-frames-193 {
    from {
      transform: translate3d(82vw, 108vh, 0);
    }
    to {
      transform: translate3d(2vw, -110vh, 0);
    }
  }
  .circle-container:nth-child(193) .circle {
    -webkit-animation-delay: 2213ms;
            animation-delay: 2213ms;
  }
  .circle-container:nth-child(194) {
    width: 2px;
    height: 2px;
    -webkit-animation-name: move-frames-194;
            animation-name: move-frames-194;
    -webkit-animation-duration: 35324ms;
            animation-duration: 35324ms;
    -webkit-animation-delay: 18519ms;
            animation-delay: 18519ms;
  }
  @-webkit-keyframes move-frames-194 {
    from {
      transform: translate3d(52vw, 107vh, 0);
    }
    to {
      transform: translate3d(81vw, -132vh, 0);
    }
  }
  @keyframes move-frames-194 {
    from {
      transform: translate3d(52vw, 107vh, 0);
    }
    to {
      transform: translate3d(81vw, -132vh, 0);
    }
  }
  .circle-container:nth-child(194) .circle {
    -webkit-animation-delay: 3765ms;
            animation-delay: 3765ms;
  }
  .circle-container:nth-child(195) {
    width: 5px;
    height: 5px;
    -webkit-animation-name: move-frames-195;
            animation-name: move-frames-195;
    -webkit-animation-duration: 29043ms;
            animation-duration: 29043ms;
    -webkit-animation-delay: 11ms;
            animation-delay: 11ms;
  }
  @-webkit-keyframes move-frames-195 {
    from {
      transform: translate3d(75vw, 108vh, 0);
    }
    to {
      transform: translate3d(1vw, -135vh, 0);
    }
  }
  @keyframes move-frames-195 {
    from {
      transform: translate3d(75vw, 108vh, 0);
    }
    to {
      transform: translate3d(1vw, -135vh, 0);
    }
  }
  .circle-container:nth-child(195) .circle {
    -webkit-animation-delay: 1137ms;
            animation-delay: 1137ms;
  }
  .circle-container:nth-child(196) {
    width: 6px;
    height: 6px;
    -webkit-animation-name: move-frames-196;
            animation-name: move-frames-196;
    -webkit-animation-duration: 28800ms;
            animation-duration: 28800ms;
    -webkit-animation-delay: 26763ms;
            animation-delay: 26763ms;
  }
  @-webkit-keyframes move-frames-196 {
    from {
      transform: translate3d(34vw, 102vh, 0);
    }
    to {
      transform: translate3d(96vw, -125vh, 0);
    }
  }
  @keyframes move-frames-196 {
    from {
      transform: translate3d(34vw, 102vh, 0);
    }
    to {
      transform: translate3d(96vw, -125vh, 0);
    }
  }
  .circle-container:nth-child(196) .circle {
    -webkit-animation-delay: 1003ms;
            animation-delay: 1003ms;
  }
  .circle-container:nth-child(197) {
    width: 3px;
    height: 3px;
    -webkit-animation-name: move-frames-197;
            animation-name: move-frames-197;
    -webkit-animation-duration: 31854ms;
            animation-duration: 31854ms;
    -webkit-animation-delay: 36052ms;
            animation-delay: 36052ms;
  }
  @-webkit-keyframes move-frames-197 {
    from {
      transform: translate3d(3vw, 103vh, 0);
    }
    to {
      transform: translate3d(86vw, -125vh, 0);
    }
  }
  @keyframes move-frames-197 {
    from {
      transform: translate3d(3vw, 103vh, 0);
    }
    to {
      transform: translate3d(86vw, -125vh, 0);
    }
  }
  .circle-container:nth-child(197) .circle {
    -webkit-animation-delay: 3296ms;
            animation-delay: 3296ms;
  }
  .circle-container:nth-child(198) {
    width: 3px;
    height: 3px;
    -webkit-animation-name: move-frames-198;
            animation-name: move-frames-198;
    -webkit-animation-duration: 35008ms;
            animation-duration: 35008ms;
    -webkit-animation-delay: 27898ms;
            animation-delay: 27898ms;
  }
  @-webkit-keyframes move-frames-198 {
    from {
      transform: translate3d(54vw, 104vh, 0);
    }
    to {
      transform: translate3d(53vw, -115vh, 0);
    }
  }
  @keyframes move-frames-198 {
    from {
      transform: translate3d(54vw, 104vh, 0);
    }
    to {
      transform: translate3d(53vw, -115vh, 0);
    }
  }
  .circle-container:nth-child(198) .circle {
    -webkit-animation-delay: 2427ms;
            animation-delay: 2427ms;
  }
  .circle-container:nth-child(199) {
    width: 7px;
    height: 7px;
    -webkit-animation-name: move-frames-199;
            animation-name: move-frames-199;
    -webkit-animation-duration: 30510ms;
            animation-duration: 30510ms;
    -webkit-animation-delay: 28663ms;
            animation-delay: 28663ms;
  }
  @-webkit-keyframes move-frames-199 {
    from {
      transform: translate3d(26vw, 110vh, 0);
    }
    to {
      transform: translate3d(50vw, -111vh, 0);
    }
  }
  @keyframes move-frames-199 {
    from {
      transform: translate3d(26vw, 110vh, 0);
    }
    to {
      transform: translate3d(50vw, -111vh, 0);
    }
  }
  .circle-container:nth-child(199) .circle {
    -webkit-animation-delay: 3376ms;
            animation-delay: 3376ms;
  }
  .circle-container:nth-child(200) {
    width: 2px;
    height: 2px;
    -webkit-animation-name: move-frames-200;
            animation-name: move-frames-200;
    -webkit-animation-duration: 36352ms;
            animation-duration: 36352ms;
    -webkit-animation-delay: 25999ms;
            animation-delay: 25999ms;
  }
  @-webkit-keyframes move-frames-200 {
    from {
      transform: translate3d(17vw, 110vh, 0);
    }
    to {
      transform: translate3d(61vw, -123vh, 0);
    }
  }
  @keyframes move-frames-200 {
    from {
      transform: translate3d(17vw, 110vh, 0);
    }
    to {
      transform: translate3d(61vw, -123vh, 0);
    }
  }
  .circle-container:nth-child(200) .circle {
    -webkit-animation-delay: 2522ms;
            animation-delay: 2522ms;
  }
  
  .message {
    position: absolute;
    right: 20px;
    bottom: 10px;
    color: white;
    font-family: "Josefin Slab", serif;
    line-height: 27px;
    font-size: 18px;
    text-align: right;
    pointer-events: none;
    -webkit-animation: message-frames 1.5s ease 5s forwards;
            animation: message-frames 1.5s ease 5s forwards;
    opacity: 0;
  }
  @-webkit-keyframes message-frames {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  @keyframes message-frames {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }