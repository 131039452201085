/* paginatinos tabs for grid here...  */
.paginationTabsBottom{
    width: 100%;
    background-color:white;
    box-shadow: 0px 2px 7px -4px rgb(0 0 0 / 64%);
    display:flex; 
    flex-wrap: wrap;
    justify-content:flex-start;
    align-items: center;
    padding:10px 10px;
    border-bottom-left-radius:5px;
    border-bottom-right-radius:5px;
    border-top:2px dashed lightgrey;
  }
  .paginationTabsBottom_not{
    border-top:none !important;
    border-top-left-radius:5px;
    border-top-right-radius:5px;
  }

  .paginationTabsBottom_sub{
    width: 400px !important;
    flex:6;
    background-color:white;
    /* box-shadow: 0px 2px 7px -4px rgb(0 0 0 / 64%); */
    display:flex; 
    flex-wrap: wrap;
    justify-content:flex-start;
    align-items: center;
    padding:10px 10px;
    /* border-bottom-left-radius:5px;
    border-bottom-right-radius:5px; */
    /* border-top:2px dashed lightgrey; */
  }

  .boxOfPagination{
    width: 75%;
  }
  .boxOfPagination1{
    width: 60% !important;
  
  }
  .pageNumberSet{
    width: 70px;
    height: 35px !important;
    font-size: 15px !important;
    border-radius: 2px;
    border: 1px solid lightgrey;
    padding: 2px 7px !important;
    outline-color: lightgrey !important;
    margin: 0 12px;
    

    /* outline-offset: 1px !important; */
  }
  .pageNumberSet::before{
    content: 'الصفحة';
    position: absolute;
    color: black;
    top: 10px;
    font-size: 10px;
    left:0;
    z-index:1000;
  }
  .scrollDataGrid{
    overflow-x: scroll !important;
  }
  .scrollDataGrid::-webkit-scrollbar{
    display: block !important;
    appearance: initial !important;
  }
/*   
  @media only screen and (max-width: 714px) {
    .paginationTabsBottom{
    }
    .boxOfPagination{
      width: 50%;
    }
    .scrollDataGrid_horizontal{
    }
  }
  
  @media only screen and (max-width: 650px) {
    .paginationTabsBottom{
     justify-content: space-evenly;
    }
    .boxOfPagination{
      width: 50%;
    }
  }
  .ActionIcons{
    font-family:'Cairo' !important;
    color:grey;

  } */

  .FilterSearchGrid{
    position: fixed !important;
    top:60px !important;
    left:-400px ;
    bottom:0 !important;
    /* height:100% !important; */
    width:350px !important;
    border-radius: 0 !important;
    z-index: 1061 !important;
    transition: left 0.7s ease-in !important;
    
  }
  .showFilterSearchGrid{
    left:-10px !important;
  }
  .filterActionButton{
    position: absolute;
    bottom: 60px;
    left:3px;
    background-color:#fff;
    width:100%;
    height: 70px;
  }

  .isIndashboardPage{
    position: fixed !important;
    top:60px !important;
    right:400px ;
    left: -100%;
    bottom:0 !important;
    /* height:100% !important; */
    width:350px !important;
    border-radius: 0 !important;
    z-index: 1361 !important;
    transition: left 0.7s ease-in !important;
  }

  .isIndashboardPageshowFilterSearchGrid{
    left:-100% !important;
    right:0 ;
    display: flex !important;
    justify-content:space-between !important;
    align-items: center !important;
    flex-direction: column !important;
  }